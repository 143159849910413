import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { LinearInterpolator } from "react-map-gl";
import useViewport from "hooks/useViewport";
import useMapDrag from "hooks/useMapDrag";
import useMobileDetect from "hooks/useMobileDetect";
import { ZoomIn } from "@styled-icons/open-iconic/ZoomIn";
import { ZoomOut } from "@styled-icons/open-iconic/ZoomOut";

function NavigationControls() {
  const isDraggingMap = useMapDrag((state) => state.isDraggingMap);
  const viewport = useViewport((state) => state.viewport);
  const updateViewport = useViewport((state) => state.updateViewport);
  const { isMobile } = useMobileDetect();

  function handleZoomIn() {
    updateViewport({
      zoom: viewport.zoom + 1,
      transitionDuration: 300,
      transitionEasing: (t: number) => t,
      transitionInterpolator: new LinearInterpolator()
    });
  }

  function handleZoomOut() {
    updateViewport({
      zoom: viewport.zoom - 1,
      transitionDuration: 300,
      transitionEasing: (t: number) => t,
      transitionInterpolator: new LinearInterpolator()
    });
  }

  const isSafari = navigator.vendor.includes("Apple");

  if (!isSafari) {
    return null;
  }

  return (
    <>
      {isSafari && (
        <NavigationControlsWrapper
          initial={{ y: "-50%" }}
          animate={isDraggingMap ? { x: "175%" } : { x: "0%" }}
          transition={{ ease: "linear" }}
          isMobile={isMobile()}
        >
          <IconContainer whileTap={{ y: -2 }} onClick={handleZoomIn}>
            <ZoomInIcon />
          </IconContainer>
          <IconContainer whileTap={{ y: -2 }} onClick={handleZoomOut}>
            <ZoomOutIcon />
          </IconContainer>
        </NavigationControlsWrapper>
      )}
    </>
  );
}

export default NavigationControls;

const NavigationControlsWrapper = styled(motion.div)<{ isMobile: boolean }>`
  position: absolute;
  top: 50%;
  right: ${(props) => (props.isMobile ? "4%" : "1.5%")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7rem;
`;

const IconContainer = styled(motion.div)`
  padding: 0.5rem;
  width: auto;
  height: auto;
  /* background: var(--gray); */
  /* box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5); */
  background: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 0.75rem;
`;

const ZoomInIcon = styled(ZoomIn)`
  width: 15px;
  height: 15px;
  display: block;
  color: white;
`;

const ZoomOutIcon = styled(ZoomOut)`
  width: 15px;
  height: 15px;
  display: block;
  color: white;
`;
