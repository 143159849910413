import { UserResponse } from "types";

const monthsEnum = {
  "0": "Jan",
  "1": "Feb",
  "2": "Mar",
  "3": "Apr",
  "4": "May",
  "5": "Jun",
  "6": "Jul",
  "7": "Aug",
  "8": "Sep",
  "9": "Oct",
  "10": "Nov",
  "11": "Dec"
} as {
  [key: string]: string;
};

function buildUniqueDatesFromUsers(users: UserResponse[]): string[] {
  const dates = users
    .filter((user) => {
      const time = `${user.timestamp.getMonth()}`;

      return monthsEnum[time];
    })
    .map((user) => {
      const time = `${user.timestamp.getMonth()}`;

      return `${monthsEnum[time]} ${user.timestamp.getDate()}`;
    });

  return Array.from(new Set(dates));
}

export default buildUniqueDatesFromUsers;
