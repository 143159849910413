import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import useResponseList from "hooks/useResponseList";
import useEmotionsFilter from "hooks/useEmotionsFilter";
import UserResult from "./UserResult";
import { UserResponse } from "types";

function UserResults() {
  const sidebarResponses = useResponseList((state) => state.sidebarResponses);
  const selectedEmotions = useEmotionsFilter((state) => state.selectedEmotions);

  const listRef = useRef<List | null>(null);

  const filteredResponses = sidebarResponses.filter((user: UserResponse) => {
    if (
      selectedEmotions.length === 0 ||
      selectedEmotions.includes(user.primary_emotion)
    ) {
      return true;
    } else {
      return false;
    }
  });

  const sortedResponses = filteredResponses.sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  function handleItemKey(index: number, data: UserResponse[]) {
    const item = data[index];

    return item.id;
  }

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToItem(0);
    }
  }, [sortedResponses]);

  return (
    <UserResultsWrapper>
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={listRef}
            itemCount={sortedResponses.length}
            itemSize={105}
            width={width}
            height={height}
            itemData={sortedResponses}
            itemKey={handleItemKey}
            style={{
              transform: "translateX(-50%)"
            }}
          >
            {UserResult}
          </List>
        )}
      </AutoSizer>
    </UserResultsWrapper>
  );
}

export default UserResults;

const UserResultsWrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  overflow: auto;
`;
