import getUserResponses from "./getUserResponses";
import getHighlightedUsers from "./getHighlightedUsers";
import { UserResponse, HighlightedUserResponse } from "types";

async function fetchUsers(): Promise<{
  fetchedUsers: UserResponse[];
  fetchedHighlightedUsers: HighlightedUserResponse[];
}> {
  const url = "https://hdyf-prod-api.sympler.co/api/v1/social";

  const initialData = await fetch(url).then((response) => response.json());

  const fetchedUsers = getUserResponses(initialData.response);

  const fetchedHighlightedUsers = getHighlightedUsers(initialData.highlighted);

  return { fetchedUsers, fetchedHighlightedUsers };
}

export default fetchUsers;
