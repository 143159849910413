import styled from "styled-components";
import { Close } from "@styled-icons/evil/Close";

const ExitIcon = styled(Close)`
  width: 100%;
  height: 100%;
  color: white;
  display: block;
`;

export default ExitIcon;
