import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { css } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import ReactGA from "react-ga";
import { ExitIcon } from "components/shared";
import useUI from "hooks/useUI";
import useMobileDetect from "hooks/useMobileDetect";
import useOnClickOutside from "hooks/useOnClickOutside";
import FilteringByEmotionWebm from "assets/videos/filteringbyemotion.webm";
import FilteringByEmotionMp4 from "assets/videos/filteringbyemotion.mp4";
import ViewingResponsesWebm from "assets/videos/viewingresponses.webm";
import ViewingResponsesMp4 from "assets/videos/viewingresponses.mp4";
import ContributingWebm from "assets/videos/contributing.webm";
import ContributingMp4 from "assets/videos/contributing.mp4";

const tourSlides = [
  {
    title: "Filtering by Emotion",
    text:
      "See how people are feeling across the world by using the emotion bar at the bottom of the map to select emotion(s).",
    srcWebm: FilteringByEmotionWebm,
    srcMp4: FilteringByEmotionMp4
  },
  {
    title: "Viewing Responses",
    text:
      "Click around the heatmap to view responses to questions about the crisis. You can also filter by topic.",
    srcWebm: ViewingResponsesWebm,
    srcMp4: ViewingResponsesMp4
  },
  {
    title: "Contributing to the Map",
    text:
      "Add your voice to the map by clicking on the button in the lower right-hand corner. Simply enter your location & answer a couple of questions!",
    srcWebm: ContributingWebm,
    srcMp4: ContributingMp4
  }
];

function Tutorial() {
  const showingTutorial = useUI((state) => state.showingTutorial);
  const hideTutorial = useUI((state) => state.hideTutorial);

  const { isMobile } = useMobileDetect();
  const [screenIndex, setScreenIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const handlePrev = useCallback(() => {
    if (screenIndex - 1 >= 0) {
      ReactGA.event({
        category: "Tour Modal",
        action: "User clicking previous button",
        label: `Current slide -- ${tourSlides[screenIndex].title}`
      });

      setScreenIndex((curr) => curr - 1);
    }
  }, [screenIndex]);

  const handleNext = useCallback(() => {
    if (screenIndex + 1 <= tourSlides.length - 1) {
      ReactGA.event({
        category: "Tour Modal",
        action: "User clicking next button",
        label: `Current slide -- ${tourSlides[screenIndex].title}`
      });

      setScreenIndex((curr) => curr + 1);
    }
  }, [screenIndex]);

  function handleTutorialClose() {
    ReactGA.event({
      category: "Tour Modal",
      action: "User exiting tutorial"
    });

    hideTutorial();
  }

  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (showingTutorial === true) {
        if (event.keyCode === 37) {
          handlePrev();
        }

        if (event.keyCode === 39) {
          handleNext();
        }

        if (event.keyCode === 27) {
          hideTutorial();
        }
      }
    }

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handlePrev, handleNext, hideTutorial, showingTutorial]);

  useOnClickOutside(ref, handleTutorialClose);

  const currentSlide = tourSlides[screenIndex];

  const showLeftButton = screenIndex !== 0;

  const showRightButton = screenIndex !== tourSlides.length - 1;

  return (
    <AnimatePresence exitBeforeEnter>
      {showingTutorial && (
        <TutorialWrapper
          key="wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ExitIconContainer onClick={handleTutorialClose}>
            <ExitIcon />
          </ExitIconContainer>
          <AnimatePresence exitBeforeEnter>
            <ContentContainer
              key={currentSlide.title}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              ref={ref}
            >
              <VideoContainer>
                <Video autoPlay muted loop>
                  <source src={currentSlide.srcWebm} type="video/webm" />
                  <source src={currentSlide.srcMp4} type="video/mp4" />
                </Video>
              </VideoContainer>
              <Title>{currentSlide.title}</Title>
              <TextContainer isMobile={isMobile()}>
                <Text>{currentSlide.text}</Text>
              </TextContainer>
              <ButtonsContainer>
                <ButtonContainer>
                  {showLeftButton && (
                    <NextButton
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ y: -2 }}
                      onClick={handlePrev}
                      disable={screenIndex === 0}
                    >
                      Prev
                    </NextButton>
                  )}
                </ButtonContainer>
                <Text>
                  {screenIndex + 1} / {tourSlides.length}
                </Text>
                <ButtonContainer>
                  {showRightButton && (
                    <NextButton
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ y: -2 }}
                      onClick={handleNext}
                      disable={screenIndex === tourSlides.length - 1}
                    >
                      Next
                    </NextButton>
                  )}
                </ButtonContainer>
              </ButtonsContainer>
            </ContentContainer>
          </AnimatePresence>
        </TutorialWrapper>
      )}
    </AnimatePresence>
  );
}

export default Tutorial;

const TutorialWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 70%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 750px) {
    background: rgba(0 0 0 / 0.85);
  }
`;

const ExitIconContainer = styled(motion.div)`
  position: absolute;
  top: 4%;
  left: 3%;
  width: 35px;
  height: 35px;
  cursor: pointer;
`;

const ContentContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const VideoContainer = styled(motion.div)`
  width: 375px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 1rem;
`;

const Video = styled(motion.video)`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
`;

const Title = styled(motion.div)`
  color: white;
  font-size: 1.5rem;
  letter-spacing: 1px;
  text-align: center;
  margin: 1rem 0;
`;

const TextContainer = styled(motion.div)<{ isMobile: boolean }>`
  width: 100%;
  max-width: ${(props) => (props.isMobile ? "80%" : "475px")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const Text = styled(motion.p)`
  flex: 1;
  color: white;
  line-height: 1.3;
  font-size: 0.9rem;
  letter-spacing: 0.75px;
  text-align: center;
`;

const ButtonsContainer = styled(motion.div)`
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonContainer = styled(motion.div)`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NextButton = styled(motion.button)<{
  disable?: boolean;
  selected?: boolean;
}>`
  background: cornflowerblue;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  letter-spacing: 1px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 50%);
  margin: 1rem 0;

  ${(props) =>
    props.disable &&
    css`
      filter: grayscale(100%);
    `}

  ${(props) =>
    props.selected === false &&
    css`
      background: #ccc;
      color: white;
    `}
`;
