import { LocationObject } from "types";

function removeSpaces(str: string) {
  return str?.replace(" ", "%20") || "";
}

function buildGeolocationURL(location: LocationObject) {
  const zip = removeSpaces(location.zip);
  const lat = location.latitude;
  const long = location.longitude;
  const city = removeSpaces(location.city);
  const state = removeSpaces(location.state);
  const country = removeSpaces(location.country);

  const url = `https://m.me/100775031621556?ref=${zip},${lat},${long},${city},${state},${country}`;

  return url;
}

export default buildGeolocationURL;
