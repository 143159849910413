import create from "zustand";
import { UserResponse } from "types";

export interface ResponseListProps {
  responses: UserResponse[];
  sidebarResponses: UserResponse[];
  updateSidebarResponses: (newResponses: UserResponse[]) => void;
  resetSidebarResponses: () => void;
}

const [useResponseList, api] = create<ResponseListProps>((set) => ({
  responses: [],
  sidebarResponses: [],
  updateSidebarResponses: (newResponses: UserResponse[]) =>
    set((state) => ({ sidebarResponses: newResponses })),
  resetSidebarResponses: () => set((state) => ({ sidebarResponses: [] }))
}));

export default useResponseList;

export { api };
