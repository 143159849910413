import React, { useState, useRef } from "react";
import SymplerMap from "sympler-map";
import MapGL, {
  Marker,
  ViewportProps,
  ExtraState,
  PointerEvent
} from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import ReactGA from "react-ga";
import Response from "./UserResponse";
import useViewport from "hooks/useViewport";
import useUserResponse from "hooks/useUserResponse";
import useEmotionsFilter from "hooks/useEmotionsFilter";
import useUI from "hooks/useUI";
import useMapDrag from "hooks/useMapDrag";
import useResponseList from "hooks/useResponseList";
import useInsights from "hooks/useInsights";
import useGeocoder from "hooks/useGeocoder";
import useUsers from "hooks/useUsers";
import useTimeToggle from "hooks/useTimeToggle";
import { buildLocationObject, buildGeolocationURL } from "utils";
import { UserResponse, EmotionType } from "types";
import useTopicsFilter from "hooks/useTopicsFilter";

const TOKEN =
  "pk.eyJ1IjoibWlrZW91ciIsImEiOiJja2F6d3Q2ZmswMnh0MnNtcDFmbHhyOXA4In0.IDoEem78LN1Hu9qARCAnDw";

interface MapProps {
  geocoderRef: React.MutableRefObject<HTMLDivElement | null>;
}

interface MapResponse {
  results: UserResponse[];
  event: PointerEvent;
}

function Map({ geocoderRef }: MapProps) {
  /********************** Global State  ************************/

  const users = useUsers((state) => state.users);
  const selectedTopic = useTopicsFilter((state) => state.selectedTopic);
  const viewport = useViewport((state) => state.viewport);
  const updateViewport = useViewport((state) => state.updateViewport);
  const isMapLoaded = useViewport((state) => state.isMapLoaded);
  const updateMapLoaded = useViewport((state) => state.updateMapLoaded);
  const userResponse = useUserResponse((state) => state.userResponse);
  const resetUserResponse = useUserResponse((state) => state.resetUserResponse);
  const showingUserResponse = useUI((state) => state.showingUserResponse);
  const showUserResponse = useUI((state) => state.showUserResponse);
  const hideUserResponse = useUI((state) => state.hideUserResponse);
  const selectedEmotions = useEmotionsFilter((state) => state.selectedEmotions);
  const showingSidebar = useUI((state) => state.showingSidebar);
  const showSidebar = useUI((state) => state.showSidebar);
  const hideSidebar = useUI((state) => state.hideSidebar);
  const showingOptionsBar = useUI((state) => state.showingOptionsBar);
  const hideOptions = useUI((state) => state.hideOptionsBar);
  const updateMapDrag = useMapDrag((state) => state.updateMapDrag);
  const updateSidebarResponses = useResponseList(
    (state) => state.updateSidebarResponses
  );
  const filterStartTime = useTimeToggle((state) => state.filterStartTime);
  const filterEndTime = useTimeToggle((state) => state.filterEndTime);
  const inInsightMode = useInsights((state) => state.inInsightMode);
  const setTransitionBetweenInsights = useInsights(
    (state) => state.setTransitionBetweenInsights
  );
  const showingAddWidget = useUI((state) => state.showingAddWidget);
  const showingGeocoder = useGeocoder((state) => state.showingGeocoder);
  const updateGeolocationURL = useGeocoder(
    (state) => state.updateGeolocationURL
  );
  const resetGeolocationURL = useGeocoder((state) => state.resetGeolocationURL);

  /************************ Local State ************************/

  const [offsets, setOffsets] = useState({ left: 0, top: 0 });
  const [emotions] = useState<EmotionType[]>([
    "fear",
    "sadness",
    "disgust",
    "joy",
    "surprise",
    "anger"
  ]);

  /************************** Refs *****************************/

  const mapRef = useRef<MapGL | null>(null);
  const markerRef = useRef<HTMLDivElement | null>(null);

  /************************ Mouse State ************************/

  /********************** Event Handlers ***********************/

  function handleMapClick({ results }: MapResponse) {
    if (inInsightMode === false) {
      ReactGA.event({
        category: "Map",
        action: "User has clicked on the map to find responses"
      });

      if (results.length > 0) {
        showSidebar();
      }

      if (results.length === 0 && showingSidebar === true) {
        hideSidebar();
      }

      if (showingOptionsBar) {
        hideOptions();
      }

      hideUserResponse();
      resetUserResponse();
      updateSidebarResponses(results);
    }
  }

  function handleMapMove(viewport: ViewportProps) {
    updateViewport(viewport);
  }

  function handleInteractionStateChange(state: ExtraState) {
    if (inInsightMode === false) {
      if (state.inTransition) {
        hideUserResponse();
      }

      if (!state.inTransition && userResponse?.lat && userResponse?.long) {
        showUserResponse();
      }

      if (state.isDragging) {
        // updateMapDrag(true);
      }

      if (!state.isDragging) {
        updateMapDrag(false);
      }

      if (state.inTransition && state.isZooming && state.isPanning) {
        updateMapDrag(true);
      }

      if (!state.inTransition && !state.isZooming && !state.isPanning) {
        updateMapDrag(false);
      }
    } else {
      if (state.inTransition) {
        setTransitionBetweenInsights(true);
      }

      if (!state.inTransition) {
        setTransitionBetweenInsights(false);
      }
    }
  }

  function handleMapLoad() {
    if (isMapLoaded === false) {
      updateMapLoaded(true);
    }
  }

  async function handleResult(event: any) {
    const locationObject = await buildLocationObject(
      event.result.center[0],
      event.result.center[1]
    );

    const geolocationURL = buildGeolocationURL(locationObject);

    ReactGA.event({
      category: "Add Your Voice",
      action: "User entered location into search input",
      label: locationObject.city
    });

    updateGeolocationURL(geolocationURL);
  }

  function handleClear() {
    resetGeolocationURL();
  }

  const filteredResponses = users
    .filter((response) => {
      if (selectedTopic.length > 0) {
        return response.topic === selectedTopic;
      }

      return true;
    })
    .filter((response) => {
      if (filterStartTime !== null && filterEndTime !== null) {
        const userTime = response.timestamp.getTime();

        return userTime > filterStartTime && userTime < filterEndTime;
      }

      return true;
    });

  return (
    <SymplerMap
      mapRef={mapRef}
      viewport={viewport}
      responses={filteredResponses}
      emotions={emotions}
      filteredEmotions={selectedEmotions}
      onMapClick={handleMapClick}
      onMapMove={handleMapMove}
      onMapChange={handleInteractionStateChange}
      onMapLoad={handleMapLoad}
      mapboxToken={TOKEN}
    >
      {showingUserResponse && (
        <Marker
          latitude={userResponse.lat}
          longitude={userResponse.long}
          offsetLeft={offsets?.left}
          offsetTop={offsets?.top}
        >
          <Response
            markerRef={markerRef}
            user={userResponse}
            setOffsets={setOffsets}
          />
        </Marker>
      )}

      {showingAddWidget && showingGeocoder && (
        <Geocoder
          mapRef={mapRef}
          containerRef={geocoderRef}
          onResult={handleResult}
          onClear={handleClear}
          mapboxApiAccessToken={TOKEN}
          limit={2}
          placeholder="Enter your city or town"
        />
      )}
    </SymplerMap>
  );
}

export default Map;
