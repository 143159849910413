import React from "react";
import styled, { CSSProperties } from "styled-components";
import { motion } from "framer-motion";
import { FlyToInterpolator } from "react-map-gl";
import ReactGA from "react-ga";
import TimeAgo from "timeago-react";
import UserProfilePicture from "components/UserProfilePicture";
import useViewport from "hooks/useViewport";
import useUserResponse from "hooks/useUserResponse";
import useMobileDetect from "hooks/useMobileDetect";
import useUI from "hooks/useUI";
import { Video } from "@styled-icons/evaicons-solid/Video";
import emotionalColors from "styles/emotionalColors";
import { UserResponse } from "types";

interface UserResultProps {
  index: number;
  data: UserResponse[];
  style: CSSProperties;
}

function UserResult({ index, data, style }: UserResultProps) {
  const updateViewport = useViewport((state) => state.updateViewport);
  const updateUserResponse = useUserResponse(
    (state) => state.updateUserResponse
  );
  const showingUserResponse = useUI((state) => state.showingUserResponse);
  const showUserResponse = useUI((state) => state.showUserResponse);
  const toggleSidebar = useUI((state) => state.toggleSidebar);

  const { isMobile } = useMobileDetect();

  const user = data[index];

  function handleClick() {
    ReactGA.event({
      category: "Sidebar",
      action: "User has clicked on a response in the Sidebar."
    });

    updateViewport({
      latitude: user.lat,
      longitude: user.long,
      transitionInterpolator: new FlyToInterpolator({ speed: 3 }),
      transitionDuration: "auto"
    });

    updateUserResponse(user);

    if (showingUserResponse === false) {
      showUserResponse();
    }

    if (isMobile()) {
      toggleSidebar();
    }
  }

  const isAMediaResponse = user.sympler_mirror.endsWith(".mp4");

  const mediaWithText = isAMediaResponse && user.user_input?.length > 0;

  const isSafari = navigator.vendor.includes("Apple");

  return (
    <UserResultWrapper
      style={{
        ...style,
        width: "90%",
        height: "auto"
      }}
      onClick={handleClick}
      usersPrimaryEmotion={user.primary_emotion}
      whileHover={isSafari ? undefined : { scale: 1.05 }}
      whileTap={isSafari ? undefined : { y: -2 }}
    >
      <UserResultProfilePictureContainer
        initial={{ scale: 0.9 }}
        animate={{ scale: 1.45 }}
      >
        <UserProfilePicture src={user.social_profile_picture} />
      </UserResultProfilePictureContainer>
      <UserResultTextContainer>
        {user.user_input.length > 0 ? (
          <TextContent>"{user.user_input}"</TextContent>
        ) : (
          <VideoContainer>
            <TextVideoContainer>
              <VideoIcon />
            </TextVideoContainer>
          </VideoContainer>
        )}

        <TimestampContainer>
          <Timestamp datetime={user.timestamp} locale="en_US" />
        </TimestampContainer>
        {mediaWithText && (
          <VideoIconContainer>
            <VideoIcon />
          </VideoIconContainer>
        )}
      </UserResultTextContainer>
    </UserResultWrapper>
  );
}

export default React.memo(UserResult);

const UserResultWrapper = styled(motion.div)<{
  usersPrimaryEmotion?:
    | boolean
    | "anger"
    | "sadness"
    | "fear"
    | "joy"
    | "surprise"
    | "disgust"
    | undefined;
}>`
  width: 90%;
  max-height: 75px;
  height: fit-content;
  padding: 0.25rem 0.5rem 0.25rem 1.5rem;
  margin: 1rem;
  background: ${(props) => {
    switch (props.usersPrimaryEmotion) {
      case "sadness":
        return emotionalColors.sadness[2];
      case "anger":
        return emotionalColors.anger[2];
      case "joy":
        return emotionalColors.joy[2];
      case "disgust":
        return emotionalColors.disgust[2];
      case "surprise":
        return emotionalColors.surprise[2];
      case "fear":
        return emotionalColors.fear[2];
      default:
        return "whitesmoke";
    }
  }};
  border-radius: 10px;
  display: grid;
  grid-template-areas: "picture text";
  grid-template-columns: auto 1fr;
  cursor: pointer;
  position: relative;
`;

const UserResultProfilePictureContainer = styled(motion.div)`
  grid-area: picture;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  padding: 1px;
  background: whitesmoke;
`;

const UserResultTextContainer = styled(motion.div)`
  grid-area: text;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const TextContent = styled(motion.div)`
  width: 100%;
  height: auto;
  letter-spacing: 0.25px;
  padding-left: 1.5rem;
  font-size: 0.8rem;
  line-height: 1.3;
  text-align: left;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
`;

const TimestampContainer = styled(motion.div)`
  position: absolute;
  bottom: -27.5%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  background: whitesmoke;
  padding: 0.25rem 0.5rem;
  box-shadow: 10px 10px 33px -15px rgba(0, 0, 0, 0.47);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
`;

const Timestamp = styled(TimeAgo)`
  font-size: 0.65rem;
  text-transform: uppercase;
`;

const VideoIconContainer = styled(motion.div)`
  position: absolute;
  bottom: -27.5%;
  right: 10%;
  transform: translateX(50%);
  background: whitesmoke;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
`;

const VideoIcon = styled(Video)`
  width: 100%;
  height: 100%;
  display: block;
  color: black;
`;

const VideoContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const TextVideoContainer = styled(motion.div)`
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
