import create from "zustand";

interface TopicsFilterState {
  topics: string[];
  selectedTopic: string;
  updateTopics: (topics: string[]) => void;
  updateSelectedTopic: (topic: string) => void;
}

const [useTopicsFilter, api] = create<TopicsFilterState>((set) => ({
  topics: [],
  selectedTopic: "",
  updateTopics: (topics: string[]) => set((state) => ({ topics })),
  updateSelectedTopic: (selectedTopic: string) =>
    set((state) => ({ selectedTopic }))
}));

export default useTopicsFilter;

export { api };
