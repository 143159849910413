import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence, Variants } from "framer-motion";
import ReactGA from "react-ga";
import useMapDrag from "hooks/useMapDrag";
import useEmotionsFilter from "hooks/useEmotionsFilter";
import useUserResponse from "hooks/useUserResponse";
import useMobileDetect from "hooks/useMobileDetect";
import useViewport from "hooks/useViewport";
import useUI from "hooks/useUI";
import { useCookies } from "react-cookie";
import emotionalColors from "styles/emotionalColors";
import { EmotionType } from "types";

const data = [
  {
    emotion: "Anger",
    end: "left"
  },
  {
    emotion: "Surprise"
  },
  {
    emotion: "Joy"
  },
  {
    emotion: "Disgust"
  },
  {
    emotion: "Sadness"
  },
  {
    emotion: "Fear",
    end: "right"
  }
];

const parent: Variants = {
  hidden: { y: "-100%", opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  },
  exit: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.1,
      staggerDirection: -1
    }
  }
};

const child: Variants = {
  hidden: (color) => ({ opacity: 0, y: 25 }),
  visible: (color) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 }
  }),
  exit: { opacity: 0, y: 25, transition: { duration: 0.5 } }
};

function EmotionMapKey() {
  const [cookies] = useCookies(["first_time"]);
  const isMapLoaded = useViewport((state) => state.isMapLoaded);
  const isDraggingMap = useMapDrag((state) => state.isDraggingMap);
  const showingPianoAnimation = useUI((state) => state.showingPianoAnimation);
  const showPianoAnimation = useUI((state) => state.showPianoAnimation);
  const hidePianoAnimation = useUI((state) => state.hidePianoAnimation);
  const showingToast = useUI((state) => state.showingToast);
  const showingTutorial = useUI((state) => state.showingTutorial);

  const { isMobile: isMobileUser } = useMobileDetect();
  const [currentEmotion] = useState("");

  const firstTime = cookies.first_time === undefined;
  const isDesktop = isMobileUser() === false;

  useEffect(() => {
    if (firstTime === false) {
      if (isMapLoaded === true) {
        if (isDesktop === true) {
          if (showingToast === false) {
            if (showingTutorial === false) {
              showPianoAnimation();
            }
          }
        }
      }
    }
  }, [
    firstTime,
    isMapLoaded,
    isDesktop,
    showingToast,
    showingTutorial,
    showPianoAnimation
  ]);

  return (
    <EmotionContainer
      initial={{ x: "-50%" }}
      animate={
        isDraggingMap ? { y: "275%", scale: 0.9 } : { y: "0%", scale: 1 }
      }
      transition={{ ease: "linear" }}
      onHoverStart={showPianoAnimation}
      onHoverEnd={hidePianoAnimation}
    >
      <AnimatePresence exitBeforeEnter>
        <CurrentEmotion
          key={currentEmotion}
          initial={{ x: "-50%", y: "-150%", opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {currentEmotion}
        </CurrentEmotion>
      </AnimatePresence>
      <EmotionMapKeyWrapper>
        {data.map((emotion) => (
          <EmotionMapKeyItem key={emotion.emotion} {...emotion} />
        ))}
        <AnimatePresence exitBeforeEnter>
          {showingPianoAnimation &&
            isDesktop &&
            showingTutorial === false &&
            showingToast === false && (
              <PianoEmotionContainer
                key="parent"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={parent}
              >
                {data.map((emotion) => (
                  <PianoItem
                    key={emotion.emotion}
                    custom={
                      emotionalColors[
                        emotion.emotion.toLowerCase() as EmotionType
                      ][5]
                    }
                    variants={child}
                  >
                    {emotion.emotion}
                  </PianoItem>
                ))}
              </PianoEmotionContainer>
            )}
        </AnimatePresence>
      </EmotionMapKeyWrapper>
    </EmotionContainer>
  );
}

export default EmotionMapKey;

const EmotionContainer = styled(motion.div)`
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  width: 575px;
  height: 25px;
  background: none;
  z-index: 3;

  @media screen and (max-width: 900px) {
    width: 80%;
  }

  @media screen and (max-width: 500px) {
    bottom: 2.5%;
  }
`;

const EmotionMapKeyWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    ${emotionalColors.anger[4]} 0%,
    ${emotionalColors.anger[6]} 10%,
    ${emotionalColors.surprise[6]} 30%,
    ${emotionalColors.joy[6]} 45%,
    ${emotionalColors.disgust[6]} 60%,
    ${emotionalColors.sadness[6]} 75%,
    ${emotionalColors.fear[6]} 90%,
    ${emotionalColors.fear[4]} 100%
  );
  display: flex;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid white;
`;

const CurrentEmotion = styled(motion.div)`
  color: white;
  font-size: 1.25rem;
  letter-spacing: 1px;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 2;
`;

const PianoEmotionContainer = styled(motion.div)`
  width: 100%;
  top: 0%;
  left: 0%;
  height: 2rem;
  position: absolute;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
`;

const PianoItem = styled(motion.div)`
  flex: 1;
  color: white;
  letter-spacing: 1px;
  font-size: 0.9rem;
`;

function EmotionMapKeyItem(props: any) {
  const selectedEmotions = useEmotionsFilter((state) => state.selectedEmotions);
  const updateSelectedEmotion = useEmotionsFilter(
    (state) => state.updateSelectedEmotion
  );
  const hideUserResponse = useUI((state) => state.hideUserResponse);
  const resetUserResponse = useUserResponse((state) => state.resetUserResponse);
  const { isMobile } = useMobileDetect();

  function handleClick() {
    ReactGA.event({
      category: "Emotion Filters",
      action: "User has selected a new emotion",
      label: emotion
    });

    hideUserResponse();
    resetUserResponse();
    updateSelectedEmotion(emotion);
  }

  const emotion = props.emotion.toLowerCase() as EmotionType;
  const selected = selectedEmotions.includes(emotion);

  return (
    <EmotionMapKeyItemWrapper
      {...props}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      emotion={emotion}
      selected={selected}
      onClick={handleClick}
      data-testid={emotion}
    >
      <AnimatePresence exitBeforeEnter>
        {!selected ? (
          <Selected selected={selected} {...props}></Selected>
        ) : (
          <Selected selected={!selected} {...props}></Selected>
        )}
      </AnimatePresence>
      <AnimatePresence exitBeforeEnter>
        {selected && <Text isMobile={isMobile()}>{emotion}</Text>}
      </AnimatePresence>
    </EmotionMapKeyItemWrapper>
  );
}

const EmotionMapKeyItemWrapper = styled(motion.div)<{
  colorStart: string;
  colorEnd: string;
  end?: string;
  selected?: boolean;
  emotion: EmotionType;
}>`
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.selected ? emotionalColors[props.emotion][6] : "transparent"};
  z-index: 2000 !important;
  border-left: 1px solid white;
  border-right: 1px solid white;
  cursor: pointer;
  position: relative;
  ${(props) =>
    props.end === "left" &&
    "border-top-left-radius: 15px; border-bottom-left-radius: 15px; border-left: none;"}
  ${(props) =>
    props.end === "right" &&
    "border-top-right-radius: 15px; border-bottom-right-radius: 15px; border-right: none;"}
`;

const Selected = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 0.32633 },
  exit: { opacity: 0 }
})<{
  colorStart: string;
  colorEnd: string;
  end?: string;
  selected: boolean;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${(props) => (props.selected ? props.colorEnd : "white")};
  border-left: 1px solid white;
  border-right: 1px solid white;
  color: white;
  ${(props) =>
    props.end === "left" &&
    "border-top-left-radius: 15px; border-bottom-left-radius: 15px"}
  ${(props) =>
    props.end === "right" &&
    "border-top-right-radius: 15px; border-bottom-right-radius: 15px"};
`;

const Text = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})<{ isMobile: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  color: white;
  font-size: ${(props) => (props.isMobile ? "0.8rem" : "1rem")};
  z-index: 3;
  text-shadow: 0px 0px 0.5px black;
`;
