import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import {
  motion,
  AnimatePresence,
  useMotionValue,
  Variants
} from "framer-motion";
import { FlyToInterpolator } from "react-map-gl";
import ReactGA from "react-ga";
import SymplerLogo from "components/SymplerLogo";
import useMobileDetect from "hooks/useMobileDetect";
import useMapDrag from "hooks/useMapDrag";
import useUI from "hooks/useUI";
import useInsights from "hooks/useInsights";
import useViewport from "hooks/useViewport";
import { Lightbulb } from "@styled-icons/foundation/Lightbulb";
import { MagnifyingGlass } from "@styled-icons/foundation/MagnifyingGlass";
import { MessageRoundedAdd } from "@styled-icons/boxicons-solid/MessageRoundedAdd";
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon
} from "react-share";

const shareUrl = "https://howdoyoufeel.live";
const title = "Have you seen this yet? Check out 'How Do You Feel?'";

const POSITION = 28;
const SIZE = 30;
const PADDING = 6;

function OptionsMenu() {
  const showingOptionsBar = useUI((state) => state.showingOptionsBar);
  const showingUserResponse = useUI((state) => state.showingUserResponse);
  const showTutorial = useUI((state) => state.showTutorial);
  const showAddWidget = useUI((state) => state.showAddWidget);
  const hideUserResponse = useUI((state) => state.hideUserResponse);
  const hideOptionsBar = useUI((state) => state.hideOptionsBar);
  const isDraggingMap = useMapDrag((state) => state.isDraggingMap);
  const enterInsightMode = useInsights((state) => state.enterInsightMode);
  const highlightedUsers = useInsights((state) => state.highlightedUsers);
  const highlightedIndex = useInsights((state) => state.highlightedIndex);
  const updateViewport = useViewport((state) => state.updateViewport);
  const { isMobile } = useMobileDetect();

  const zIndex = useMotionValue(-1);
  const [shouldOpenDailyReflections, setShouldOpenDailyReflections] = useState(
    false
  );
  const [shouldStartTutorial, setShouldStartTutorial] = useState(false);
  const [shouldOpenAddWidget, setShouldOpenAddWidget] = useState(false);

  const highlightedUser = highlightedUsers[highlightedIndex];

  function handleAnimationStart() {
    if (showingOptionsBar === true) {
      zIndex.set(500);
    }
  }

  function handleAnimationEnd() {
    if (showingOptionsBar === false) {
      zIndex.set(-1);
    }

    if (shouldOpenDailyReflections === true) {
      if (showingUserResponse === true) {
        hideUserResponse();
      }

      setShouldOpenDailyReflections(false);
      enterInsightMode();

      if (highlightedUser !== undefined) {
        updateViewport({
          longitude: highlightedUser.long,
          latitude: highlightedUser.lat,
          zoom: 4,
          transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
          transitionDuration: "auto"
        });
      }
    }

    if (shouldOpenAddWidget === true) {
      setShouldOpenAddWidget(false);
      showAddWidget();
    }

    if (shouldStartTutorial === true) {
      setShouldStartTutorial(false);
      showTutorial();
    }
  }

  function handleDailyReflectionsClick() {
    ReactGA.event({
      category: "Options",
      action:
        "User has clicked Daily Reflections button inside the Options panel"
    });

    setShouldOpenDailyReflections(true);
    hideOptionsBar();
  }

  function handleAddWidgetClick() {
    ReactGA.event({
      category: "Options",
      action: "User has clicked Add Your Voice button inside the Options panel"
    });

    setShouldOpenAddWidget(true);
    hideOptionsBar();
  }

  function handleTutorialClick() {
    ReactGA.event({
      category: "Options",
      action: "User has clicked Take A Tour button inside the Options panel"
    });

    setShouldStartTutorial(true);
    hideOptionsBar();
  }

  function handleSymplerLogoClick() {
    ReactGA.event({
      category: "Options",
      action: "User has clicked the Sympler Logo inside the Options panel"
    });
  }

  function handleContactUsClick() {
    ReactGA.event({
      category: "Options",
      action: "User has clicked the Contact Us link inside the Options panel"
    });
  }

  function handlePrivacyPolicyClick() {
    ReactGA.event({
      category: "Options",
      action:
        "User has clicked the Privacy Policy link inside the Options panel"
    });
  }

  function handleFacebookClick() {
    ReactGA.event({
      category: "Options",
      action:
        "User has clicked the Share on Facebook link inside the Options panel"
    });
  }

  function handleTwitterClick() {
    ReactGA.event({
      category: "Options",
      action: "User has clicked the Share on Twitter inside the Options panel"
    });
  }

  function handleRedditClick() {
    ReactGA.event({
      category: "Options",
      action:
        "User has clicked the Share on Reddit link inside the Options panel"
    });
  }

  function handleEmailClick() {
    ReactGA.event({
      category: "Options",
      action: "User has clicked the Share via email inside the Options panel"
    });
  }

  useEffect(() => {
    if (isDraggingMap === true && showingOptionsBar === true) {
      hideOptionsBar();
    }
  }, [isDraggingMap, showingOptionsBar, hideOptionsBar]);

  const applyMobileStyling = isMobile();

  return (
    <OptionsMenuWrapper
      style={{ zIndex }}
      animate={isDraggingMap ? { x: "150%" } : { x: "0%" }}
      transition={{ ease: "linear" }}
      applyMobileStyling={applyMobileStyling}
    >
      <Background
        initial={false}
        animate={showingOptionsBar ? "visible" : "hidden"}
        variants={sidebar}
        onAnimationStart={handleAnimationStart}
        onAnimationComplete={handleAnimationEnd}
      >
        <AnimatePresence exitBeforeEnter>
          {showingOptionsBar && (
            <>
              <Overview
                key="Overview"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={list}
                data-testid="options-bar"
              >
                <OverviewHeader>
                  Welcome to <span>How Do You Feel</span>
                </OverviewHeader>
                <OverviewText>
                  Explore how people are feeling right now
                </OverviewText>
                <OverviewText>
                  Use the map to navigate people’s stories around the world
                </OverviewText>
                <OverviewText>
                  And add your voice to the conversation
                </OverviewText>
              </Overview>
              <ActionsList
                key="ActionsList"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={list}
              >
                <ActionTextLink
                  onClick={handleAddWidgetClick}
                  variants={listItem}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ y: -2 }}
                  button
                >
                  Add your voice
                  <ExternalIconContainer variants={listItem}>
                    <ExternalIcon />
                  </ExternalIconContainer>
                </ActionTextLink>

                <ActionText
                  variants={listItem}
                  whileHover={{ scale: 1.05, cursor: "pointer" }}
                  whileTap={{ y: -2 }}
                  onClick={handleDailyReflectionsClick}
                >
                  Daily Reflection{" "}
                  <ExternalIconContainer
                    variants={listItem}
                    // onClick={startAutomaticTour}
                  >
                    <ReflectionIcon />
                  </ExternalIconContainer>
                </ActionText>

                <ActionText
                  variants={listItem}
                  whileHover={{ scale: 1.05, cursor: "pointer" }}
                  whileTap={{ y: -2 }}
                  onClick={handleTutorialClick}
                >
                  Take a Tour{" "}
                  <ExternalIconContainer
                    variants={listItem}
                    // onClick={startAutomaticTour}
                  >
                    <TourIcon />
                  </ExternalIconContainer>
                </ActionText>

                <div>
                  <ShareLabel variants={listItem}>Share</ShareLabel>
                  <ShareButtonGroup variants={listItem}>
                    <FacebookShareButton
                      url={shareUrl}
                      quote={title}
                      onClick={handleFacebookClick}
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={shareUrl}
                      title={title}
                      onClick={handleTwitterClick}
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    <RedditShareButton
                      url={shareUrl}
                      title={title}
                      windowWidth={660}
                      windowHeight={460}
                      onClick={handleRedditClick}
                    >
                      <RedditIcon size={32} round />
                    </RedditShareButton>
                    <EmailShareButton
                      url={shareUrl}
                      subject={title}
                      onClick={handleEmailClick}
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                  </ShareButtonGroup>
                </div>
              </ActionsList>

              <Footer
                key="Footer"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={listItem}
              >
                <SymplerLogoContainer
                  key="logo"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={listItem}
                  href="https://sympler.co"
                  target="_blank"
                  rel="noreferrer"
                  whileHover={{ scale: 1.05 }}
                  onClick={handleSymplerLogoClick}
                >
                  <SymplerLogo black />
                </SymplerLogoContainer>

                <LinkGroup>
                  <Link
                    href="mailto:info+hdyf@sympler.co?subject=How%20Do%20You%20Feel"
                    whileHover={{ scale: 1.05 }}
                    onClick={handleContactUsClick}
                  >
                    Contact Us
                  </Link>
                  <Link
                    download
                    href="privacy.pdf"
                    whileHover={{ scale: 1.05 }}
                    onClick={handlePrivacyPolicyClick}
                  >
                    Privacy Policy
                  </Link>
                </LinkGroup>
              </Footer>
            </>
          )}
        </AnimatePresence>
      </Background>
    </OptionsMenuWrapper>
  );
}

export default OptionsMenu;

const OptionsMenuWrapper = styled(motion.div)<{
  applyMobileStyling?: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 315px;
  max-width: 20%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  user-select: none;
  outline: none;
  border: none;
  overflow: hidden;
  ${(props) =>
    props.applyMobileStyling &&
    css`
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    `}

  @media screen and (max-width: 500px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

const Background = styled(motion.div)`
  background: whitesmoke;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 3;
  display: grid;
  grid-template-areas:
    "."
    "overview"
    "actions"
    "."
    "footer";
  grid-template-rows: 1fr auto auto 1fr auto;
  place-items: center;
  padding: 2rem 1rem;
  overflow-y: auto;
`;

const Overview = styled(motion.div)`
  grid-area: overview;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (max-width: 500px) {
    max-width: 80%;
  }
`;

const OverviewHeader = styled(motion.div)`
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  display: block;
  margin: 0.75rem 0;
  color: var(--text);

  span {
    display: block;
    text-transform: uppercase;
    font-size: 1.5rem;
  }
`;

const OverviewText = styled(motion.div)`
  font-size: 0.9rem;
  letter-spacing: 1px;
  line-height: 1.3;
  text-align: center;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
  color: var(--text);
`;

const ActionsList = styled(motion.div)`
  grid-area: actions;
  background: transparent;
  border-radius: 10px;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  max-width: 300px;
  z-index: 5;
`;

const ActionText = styled(motion.div)<{ button?: boolean }>`
  letter-spacing: 1px;
  font-size: 0.9rem;
  justify-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 90%;
  color: var(--text);

  ${(props) =>
    props.button &&
    css`
      border: 1px solid black;
      padding: 0.5rem 0.25rem;
      border-radius: 15px;
    `}
`;

const ShareLabel = styled(motion.div)`
  justify-self: center;
  text-align: center;
  letter-spacing: 1px;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const ActionTextLink = styled(motion.div)<{ button?: boolean }>`
  text-align: center;
  letter-spacing: 1px;
  font-size: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  width: 100%;
  color: var(--text);
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  ${(props) =>
    props.button &&
    css`
      background: var(--pink);
      padding: 0.5rem 0.25rem;
      border-radius: 15px;
      color: white;
    `}
`;

const ShareButtonGroup = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  text-align: right;
  padding: 0 1rem;
`;

const ExternalIconContainer = styled(motion.div)`
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
`;

const ExternalIcon = styled(MessageRoundedAdd)`
  width: 100%;
  height: 100%;
  color: white;
  display: block;
`;

const ReflectionIcon = styled(Lightbulb)`
  width: 100%;
  height: 100%;
  color: var(--text);
  display: block;
`;

const TourIcon = styled(MagnifyingGlass)`
  width: 100%;
  height: 100%;
  color: var(--text);
  display: block;
`;

const SymplerLogoContainer = styled(motion.a)`
  width: 100%;
  height: 35px;
  flex: 3;
`;

const Footer = styled(motion.div)`
  grid-area: footer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  width: 100%;
  color: cornflowerblue;
`;

const LinkGroup = styled(motion.div)`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Link = styled(motion.a)`
  font-size: 0.8rem;
  font-style: none;
  text-decoration: none;
  color: cornflowerblue;
  cursor: pointer;

  &:visited,
  &:hover,
  &:active {
    color: cornflowerblue;
  }

  &:last-child {
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1rem;
  }
`;

const list: Variants = {
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      when: "afterChildren"
    }
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const listItem: Variants = {
  hidden: { opacity: 0, y: 2 },
  visible: { opacity: 1, y: 0 }
};

const sidebar: Variants = {
  visible: {
    clipPath: `circle(2200px at top ${POSITION - PADDING * -1}px right ${
      POSITION - PADDING * -1
    }px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      when: "afterChildren"
    }
  },
  hidden: {
    clipPath: `circle(${SIZE - PADDING * 4}px at top ${
      POSITION - PADDING * -1
    }px right ${POSITION - PADDING * -1}px)`,
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
      when: "beforeChildren",
      staggerChildren: 0.2
    }
  }
};
