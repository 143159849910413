import { useCallback } from "react";
import { useCookies } from "react-cookie";

function useHDYFCookies(numOfInsights: number) {
  const [cookies, setCookie, removeCookie] = useCookies(["reflections"]);

  const cookieHighlightLength = Number(cookies.reflections);

  const shouldSetCookie = cookieHighlightLength !== numOfInsights;

  const addCookie = useCallback(
    () =>
      setCookie("reflections", numOfInsights, {
        path: "/",
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
      }),
    [numOfInsights, setCookie]
  );

  const deleteCookie = useCallback(() => removeCookie("reflections"), [
    removeCookie
  ]);

  return {
    cookie: cookies.reflections,
    shouldSetCookie,
    addCookie,
    deleteCookie
  };
}

export default useHDYFCookies;
