import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import ReactGA from "react-ga";
import useUI from "hooks/useUI";
import useTopicsFilter from "hooks/useTopicsFilter";
import useUserResponse from "hooks/useUserResponse";
import useResponseList from "hooks/useResponseList";
import useOnClickOutside from "hooks/useOnClickOutside";
import { CaretDown } from "@styled-icons/boxicons-regular/CaretDown";

interface TopicsFilterProps {
  topics: string[];
}

function TopicsFilter({ topics }: TopicsFilterProps) {
  const selectedTopic = useTopicsFilter((state) => state.selectedTopic);
  const updateSelectedTopic = useTopicsFilter(
    (state) => state.updateSelectedTopic
  );
  const resetUserResponse = useUserResponse((state) => state.resetUserResponse);
  const hideUserResponse = useUI((state) => state.hideUserResponse);
  const resetSidebarResponses = useResponseList(
    (state) => state.resetSidebarResponses
  );

  const [showDropdown, setShowDropdown] = useState(false);

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(wrapperRef, handleClickOutside);

  function handleClick() {
    ReactGA.event({
      category: "Sidebar",
      action: "User has clicked on the Topics Filter dropdown"
    });

    setShowDropdown((prevState) => !prevState);
  }

  function handleClickOutside() {
    setShowDropdown(false);
  }

  function handleSelectAllClick() {
    updateSelectedTopic("");
    setShowDropdown(false);
  }

  useEffect(() => {
    // Whenever we update topic, reset all UserResponse / responses state
    hideUserResponse();
    resetUserResponse();
    resetSidebarResponses();
  }, [
    selectedTopic,
    hideUserResponse,
    resetUserResponse,
    resetSidebarResponses
  ]);

  return (
    <Wrapper ref={wrapperRef}>
      <TopicsFilterWrapper>
        <TopicsFilterInformation onClick={handleClick}>
          <TopicsFilterHeader>Topic</TopicsFilterHeader>
          <TopicsFilterContent>
            <TopicsFilterCategory data-testid="filter-topic">
              {selectedTopic || "All Topics"}
            </TopicsFilterCategory>
            <TopicsFilterIconContainer
              data-testid="filter-toggle"
              whileHover={{ backgroundColor: "#e0e0e0" }}
            >
              <TopicsFilterIcon />
            </TopicsFilterIconContainer>
          </TopicsFilterContent>
        </TopicsFilterInformation>
      </TopicsFilterWrapper>

      {showDropdown && (
        <TopicsFilterDropdown
          initial={{
            x: "-50%",
            y: "100%"
          }}
        >
          {topics &&
            topics
              .filter((topic: string) => topic !== selectedTopic)
              .map((topic: string) => (
                <TopicsFilterDropdownItem
                  key={topic}
                  onClick={() => {
                    ReactGA.event({
                      category: "Sidebar",
                      action: "User has selected a new topic",
                      label: topic
                    });

                    updateSelectedTopic(topic);
                    setShowDropdown(false);
                  }}
                >
                  {topic}
                </TopicsFilterDropdownItem>
              ))}
          <TopicsFilterDropdownItem
            special={true}
            onClick={handleSelectAllClick}
          >
            Select All Topics
          </TopicsFilterDropdownItem>
        </TopicsFilterDropdown>
      )}
    </Wrapper>
  );
}

export default TopicsFilter;

const Wrapper = styled(motion.div)`
  position: relative;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: whitesmoke;
  border-radius: 5px;
  color: black;
  border-radius: 5px;
  width: 250px;
  height: 100%;
`;

const TopicsFilterWrapper = styled(motion.div)`
  color: black;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  z-index: 10;
  width: 100%;
  padding: 0 0.5rem;
`;

const TopicsFilterInformation = styled(motion.div)`
  padding: 0.25rem 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: max-content;
  max-width: max-content;
`;

const TopicsFilterHeader = styled.div`
  display: block;
  font-size: 0.55rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const TopicsFilterContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.25rem 0 0 0;
`;

const TopicsFilterCategory = styled.div`
  display: block;
  font-size: 1rem;
  letter-spacing: 1px;
  white-space: nowrap;
`;

const TopicsFilterIconContainer = styled(motion.div)`
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 0.5rem;
  border-radius: 50%;
`;

const TopicsFilterIcon = styled(CaretDown)`
  width: 100%;
  height: 100%;
  color: black;
  display: block;
`;

const TopicsFilterDropdown = styled(motion.div)`
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: whitesmoke;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.25rem 1rem 0.5rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform-origin: top center;
  z-index: 10;
`;

const TopicsFilterDropdownItem = styled(motion.div).attrs({
  whileHover: { backgroundColor: "#e0e0e0" }
})<{ special?: boolean }>`
  width: 100%;
  background: whitesmoke;
  padding: 0.5rem 0.75rem;
  display: block;
  font-size: 0.8rem;
  letter-spacing: 1px;
  border-radius: 5px;
  cursor: pointer;
  ${(props) =>
    props.special &&
    css`
      border-top: 1px solid black;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `};

  margin: 0.1rem 0;
`;
