import { EmotionScore, EmotionType } from "types";

function determinePrimaryEmotion(emotionScores: EmotionScore) {
  const primaryEmotionPair = Object.entries(emotionScores).reduce(
    ([highestEmotion, highestEmotionScore], [emotion, emotionScore]) => {
      if (emotionScore > highestEmotionScore) {
        return [emotion, emotionScore];
      } else {
        return [highestEmotion, highestEmotionScore];
      }
    }
  );

  const [primaryEmotion] = primaryEmotionPair;

  return primaryEmotion as EmotionType;
}

export default determinePrimaryEmotion;
