function range(start: number, end: number) {
  return Array(end - start + 1)
    .fill(null)
    .map((_: number, idx: number) => start + idx);
}

const possibilities = {
  topics: [
    "Community Response",
    "Government Response",
    "Media Response",
    "Isolation",
    "Public Health",
    "The Future"
  ],
  emotions: ["sadness", "anger", "joy", "disgust", "surprise", "fear"],
  videos: [
    "http://dashboard.sympler.live/assets/uploads/video-1584573838.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1584575823.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1584574439.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1584573892.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1577989965.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1577980916.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1577980868.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1577977811.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1570389446.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1570384486.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1570387929.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1570388793.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1570391142.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1570387713.mp4",
    "http://dashboard.sympler.live/assets/uploads/video-1572907500.mp4"
  ],
  emotionScores: range(1, 100).map((score) => score / 100),
  latitudes: range(30, 47),
  longitudes: range(-123, -75),
  nyc: {
    latitudes: [40],
    longitudes: range(-74, -73)
  },
  userInput: [
    "Store shelves are empty all over. Kids are still playing outside together, many of them. Grocery stores are packed with long lines. Its nearly impossible to find bread, water, potatoes, and toilet paper anywhere. I also have had to take in a child family member due to her not being in school. I'm calm and trying to be patient about when this will be over.",
    "First, the impact is crazy. Nobody  outside as it was 2 weeks ago. Everything it's all calm outside. The people reaction it's insane at the stores. I went to purchase some eggs. And they are permitting  1 per person. And most food and stuff are gone. Like really it's not the end of the world.",
    "Cant go out to eat so no movies restaurant etc",
    "The stores are a little low on some things but generally it's ok.  The mood is 'cautious'. And i am worried about a lockdown but trying to stay positive.",
    "The news stations are really going insane!  It's too much.  Its causing panic.",
    "Other than hygiene and sanitizing, reach out all for help if needed, offer help if asked. Volunteer services if you can find anything. Any help is appreciated.",
    "Have a routine during isolation or go play/walk with ur dog. Going fishing and camping or taking a hike is what eases us Alaskans, summer is near to go berry picking and enjoy the fresh Alaska air",
    "Because my lifestyle is very similar to what I am living now, nothing much has changed. At some point I was only able to purchase SmartWater, which is a different purchase than I might normally make. There's nothing else that I can really think of.",
    "I don’t think about the future as the next day is never promise to us. But I do feel like this will be a big impact for the better hopefully. May people see that we truly are the cause of our own problems. Till next year I imagine peace, love and kindness to all.",
    "I think most companies are just doing what they can. And its right if it helps people.",
    "I admire everyone who is still working through this crisis, they are dedicated individuals and should be rewarded, especially anyone who is a parent with children who are out of school. Many of them are incurring additional costs for child care and food at home if they may have otherwise qualified for free lunches at school. If I could say something to them directly, it would be thank you",
    "We live in a suburb/rural area. Restaurants and bars are closed. People are staying home. Some believe it’s a conspiracy, others are more concerned and are preparing to stay home for weeks at a time. People are spooked and bored."
  ],
  questions: [
    "Got it. There’s a lot of uncertainty right now and the news can be intense. How are you coping with the onslaught of information?",
    "What about the media? This is an unprecedented situation and things are changing quickly. How do you feel about the way the media is handling this?",
    "Makes sense. Across America, people are being asked to stay home as much as possible. How are you dealing with this new way of living?",
    "Changing gears a bit, what do you think of how authorities are responding to the health crisis so far?",
    "What about the economy? How do you feel about the overall response to keeping the economy healthy?",
    "What about businesses? How are brands or companies playing a role, good or bad, in this crisis? What are they doing, and why is it right or wrong?",
    "Right on. Are there any types of brands or businesses that you’re using differently?  What businesses or brands are you using less and which are you using more? Share some examples and explain your choices a bit.",
    "What about the people at the frontlines of this crisis? How do you feel about the people who are working extra hard to get us through this? What do you want to say to them?",
    "Three questions left! What about people like you? Aside from washing our hands, covering our coughs and staying home, what can everyday people do to come together and make a difference during this trying time?"
  ]
};

export default possibilities;
