import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Range, getTrackBackground } from "react-range";
import ReactGA from "react-ga";
import useTimeToggle from "hooks/useTimeToggle";
import useMobileDetect from "hooks/useMobileDetect";
import useViewport from "hooks/useViewport";

const DATE_RANGE_UPDATE_DELAY = 100;
const GA_TRANSMIT_DELAY = 2000;

function DateRange() {
  // Global State
  const isMapLoaded = useViewport((state) => state.isMapLoaded);
  const uniqueDates = useTimeToggle((state) => state.uniqueDates);
  const dateRange = useTimeToggle((state) => state.dateRange);
  const startUniqueDateIndex = useTimeToggle((state) => state.startDateIndex);
  const endUniqueDateIndex = useTimeToggle((state) => state.endDateIndex);
  const setFilterStartTime = useTimeToggle((state) => state.setFilterStartTime);
  const setFilterEndTime = useTimeToggle((state) => state.setFilterEndTime);
  const setStartDateIndex = useTimeToggle((state) => state.setStartDateIndex);
  const setEndDateIndex = useTimeToggle((state) => state.setEndDateIndex);
  const updateDateRange = useTimeToggle((state) => state.updateDateRange);
  const { isMobile } = useMobileDetect();
  const [startingDate, endingDate] = dateRange;

  // Range Props
  const STEP = 1;
  const MIN = 0;
  const MAX = uniqueDates.length > 0 ? uniqueDates.length - 1 : 1;
  const values = [startUniqueDateIndex || MIN, endUniqueDateIndex || MAX];

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  function handleRangeChange([newStartIndex, newEndIndex]: number[]) {
    setStartDateIndex(newStartIndex);
    setEndDateIndex(newEndIndex);
  }

  useEffect(() => {
    const throttledUpdate = setTimeout(() => {
      const first = `${startingDate} 2020 00:00:00`;
      const last = `${endingDate} 2020 23:59:59`;
      const startingDateTimestamp = new Date(first).getTime();
      const endingDateTimestamp = new Date(last).getTime();

      setFilterStartTime(startingDateTimestamp);
      setFilterEndTime(endingDateTimestamp);
    }, [DATE_RANGE_UPDATE_DELAY]);

    return () => clearTimeout(throttledUpdate);
  }, [setFilterStartTime, setFilterEndTime, startingDate, endingDate]);

  useEffect(() => {
    if (startUniqueDateIndex !== null && endUniqueDateIndex !== null) {
      updateDateRange([
        uniqueDates[startUniqueDateIndex],
        uniqueDates[endUniqueDateIndex]
      ]);
    }
  }, [startUniqueDateIndex, endUniqueDateIndex, uniqueDates, updateDateRange]);

  useEffect(() => {
    const throttledSend = setTimeout(() => {
      if (isMapLoaded) {
        ReactGA.event({
          category: "Date Range",
          action: "User has moved the date range slider",
          label: `${startingDate} - ${endingDate}`
        });
      }
    }, GA_TRANSMIT_DELAY);

    return () => clearTimeout(throttledSend);
  }, [startingDate, endingDate, isMapLoaded]);

  if (startingDate.length === 0 && endingDate.length === 0) {
    return null;
  }

  return (
    <Wrapper
      isMobile={isMobile()}
      ref={wrapperRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <DateRangeWrapper>
        <DateRangeInformation>
          <DateRangeContent>
            <DateRangeText>{startingDate}</DateRangeText>
            <RangeWrapper isMobile={isMobile()}>
              <Range
                values={values}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={handleRangeChange}
                renderTrack={({ props, children }) => {
                  return (
                    <TrackWrapper
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                    >
                      <Track
                        ref={props.ref}
                        style={{
                          background: getTrackBackground({
                            values: values,
                            colors: ["#ccc", "cornflowerblue", "#ccc"],
                            min: MIN,
                            max: MAX
                          })
                        }}
                      >
                        {children}
                      </Track>
                    </TrackWrapper>
                  );
                }}
                renderThumb={({ props, isDragged }) => (
                  <OuterThumb
                    {...props}
                    data-testid={
                      props.key === 0 ? "slider-left" : "slider-right"
                    }
                  >
                    <InnerThumb isDragged={isDragged} />
                  </OuterThumb>
                )}
              />
            </RangeWrapper>
            <DateRangeText>{endingDate}</DateRangeText>
          </DateRangeContent>
        </DateRangeInformation>
      </DateRangeWrapper>
    </Wrapper>
  );
}

export default DateRange;

const Wrapper = styled(motion.div)<{ isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: whitesmoke; */
  color: black;
  border-radius: 5px;
  margin-left: 1rem;
  width: auto;
  ${(props) =>
    props.isMobile &&
    css`
      margin-left: 0;
      margin-right: 1rem;
    `}
`;

const DateRangeWrapper = styled(motion.div)`
  /* background: whitesmoke; */
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
  width: 100%;
  padding: 0 0.25rem;
  border-radius: 5px;
`;

const DateRangeInformation = styled(motion.div)`
  /* padding: 0.25rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const DateRangeContent = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* padding: 0.25rem 0 0 0; */
`;

const DateRangeText = styled(motion.div)`
  min-width: 75px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.15rem;
  letter-spacing: 1px;
  white-space: nowrap;
  margin: 0 0.5rem;
`;

const RangeWrapper = styled(motion.div)<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? "120px" : "250px")};
  height: 40px;
  margin: 0 0.5rem;
  /* background: whitesmoke; */
  border-radius: 5px;
`;

const TrackWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Track = styled(motion.div)`
  height: 10px;
  width: 100%;
  border-radius: 4px;
  align-self: center;
`;

const OuterThumb = styled(motion.div)`
  height: 17.5px;
  width: 17.5px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px #aaa;
  outline: none;
`;

const InnerThumb = styled(motion.div)<{ isDragged: boolean }>`
  width: 5px;
  height: 5px;
  outline: none;
  background-color: ${(props) => (props.isDragged ? "cornflowerblue" : "#CCC")};
`;
