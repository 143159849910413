import create from "zustand";
import { UserResponse } from "types";

interface UsersProps {
  users: UserResponse[];
  updateUsers: (newUsers: UserResponse[]) => void;
}

const [useUsers] = create<UsersProps>((set) => ({
  users: [],
  updateUsers: (newUsers: UserResponse[]) =>
    set((state) => ({
      users: newUsers,
    })),
}));

export default useUsers;
