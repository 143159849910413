import { createGlobalStyle } from "styled-components";
import "./fonts.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Sen", sans-serif;
  }

  :root {
    --pink: #FF7DA9;
    --blue: #6495ED;
    --dark-blue: #044389;
    --yellow: #FFC857;
    --orange: #E9724C;
    --gray: rgba(234, 237, 239, 95%);

    --text: black;
  }

  div,
  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  .mapboxgl-marker {
    transform: translateY(-100%);
  }

  .mapboxgl-ctrl-bottom-left {
    top: 1%;
    right: 1%;
    bottom: initial;
    left: initial;
    display: none;
  }

  /* .mapboxgl-ctrl-bottom-right {
    display: none;
  } */

  .mapboxgl-ctrl-group {
    background: none;
  }

  .mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out {
    margin: 0.5rem 0;
    background: whitesmoke !important;
    border-radius: 50%;

    &:active, &:focus {
      background: whitesmoke !important;
      border-radius: 50% !important;
      outline: none;
      border: none;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
  }

  .mapboxgl-marker {
    z-index: 100;
  }

  .__floater__arrow {
    display: none;
  }

  .mapboxgl-ctrl-geocoder {
    width: 100%;
    text-align: left;
  }
`;
