import create from "zustand";

interface MapDragState {
  isDraggingMap: boolean;
  updateMapDrag: (newState: boolean) => void;
}

const [useMapDrag, api] = create<MapDragState>((set) => ({
  isDraggingMap: false,
  updateMapDrag: (newState: boolean) =>
    set((state) => ({ isDraggingMap: newState }))
}));

export default useMapDrag;

export { api };
