const emotionalColors = {
  sadness: {
    1: "hsla(195, 100%, 92%, 0)",
    2: "rgb(51,204,255)",
    3: "rgb(0,191,255)",
    4: "rgb(0,172,230)",
    5: "rgb(0,153,204)",
    6: "rgb(0,134,179)"
  },
  anger: {
    1: "rgba(241,166,166, 0)",
    2: "rgb(241,166,166)",
    3: "rgb(237,144,145)",
    4: "rgb(230,100,101)",
    5: "rgb(223,56,57)",
    6: "rgb(218,35,37)"
  },
  joy: {
    1: "rgba(252,252,138, 0)",
    2: "rgb(252,252,138)",
    3: "rgb(251,251,100)",
    4: "rgb(251,250,81)",
    5: "rgb(250,249,42)",
    6: "rgb(249,248,23)"
  },
  disgust: {
    1: "rgba(185,235,134, 0)",
    2: "rgb(185,235,134)",
    3: "rgb(166,230,100)",
    4: "rgb(156,227,83)",
    5: "rgb(147,225,66)",
    6: "rgb(137,222,49)"
  },
  fear: {
    1: "rgba(174,117,233, 0)",
    2: "rgb(174,117,233)",
    3: "rgb(164,100,230)",
    4: "rgb(154,83,227)",
    5: "rgb(144,66,225)",
    6: "rgb(134,49,222)"
  },
  surprise: {
    1: "rgba(238,194,150, 0)",
    2: "rgb(238,194,150)",
    3: "rgb(233,174,117)",
    4: "rgb(230,164,100)",
    5: "rgb(225,144,66)",
    6: "rgb(222,134,49)"
  }
};

export default emotionalColors;
