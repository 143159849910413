import create from "zustand";

interface GeocoderProps {
  showingGeocoder: boolean;
  geolocationURL: string;
  showGeocoder: () => void;
  hideGeocoder: () => void;
  toggleGeocoder: () => void;
  updateGeolocationURL: (geolocationURL: string) => void;
  resetGeolocationURL: () => void;
}

const [useGeocoder] = create<GeocoderProps>((set) => ({
  showingGeocoder: true,
  geolocationURL: "",
  showGeocoder: () => set((state) => ({ showingGeocoder: true })),
  hideGeocoder: () => set((state) => ({ showingGeocoder: false })),
  toggleGeocoder: () =>
    set((state) => ({ showingGeocoder: !state.showingGeocoder })),
  updateGeolocationURL: (geolocationURL: string) =>
    set((state) => ({
      geolocationURL
    })),
  resetGeolocationURL: () => set((state) => ({ geolocationURL: "" }))
}));

export default useGeocoder;
