import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import ReactGA from "react-ga";
import OptionsMenu from "components/OptionsMenu";
import useUI from "hooks/useUI";
import useMapDrag from "hooks/useMapDrag";
import { DotsVerticalRounded } from "@styled-icons/boxicons-regular/DotsVerticalRounded";

const POSITION = 28;
const SIZE = 30;
const PADDING = 6;

function Options() {
  const isDraggingMap = useMapDrag((state) => state.isDraggingMap);
  const showingOptionsBar = useUI((state) => state.showingOptionsBar);
  const toggleOptionsBar = useUI((state) => state.toggleOptionsBar);

  const color = showingOptionsBar ? "#ffffff" : "#000000";

  function handleClick() {
    ReactGA.event({
      category: "Options",
      action: `User has ${
        showingOptionsBar ? "closed" : "opened"
      } the options bar`
    });

    toggleOptionsBar();
  }

  return (
    <>
      <OptionsIconContainer
        data-testid="options-icon"
        onClick={handleClick}
        initial={{
          color,
          boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
          rotate: 0,
          x: "0%",
          scale: 1,
          backgroundColor: "#f5f5f5"
        }}
        animate={
          isDraggingMap
            ? { x: "155%" }
            : showingOptionsBar
            ? {
                color,
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
                rotate: -90,
                scale: 1.15,
                backgroundColor: "rgba(255, 125, 169, 95%)"
              }
            : {
                color,
                boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
                rotate: 0,
                x: "0%",
                scale: 1,
                backgroundColor: "#f5f5f5"
              }
        }
        transition={{ ease: "linear" }}
      >
        <OptionsIcon />
      </OptionsIconContainer>

      <OptionsMenu />
    </>
  );
}

export default Options;

const OptionsIconContainer = styled(motion.div)`
  position: absolute;
  right: ${POSITION - SIZE / 2}px;
  top: ${POSITION - SIZE / 4}px;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 501;
  cursor: pointer;
  border-radius: 50%;
  background: var(--gray);
  background: "#f5f5f5";
  color: "#000000";
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  padding: ${PADDING}px;
`;

const OptionsIcon = styled(motion.custom(DotsVerticalRounded))`
  width: ${SIZE - PADDING / 2}px;
  height: ${SIZE - PADDING / 2}px;
  display: block;
  color: inherit;
`;

// eslint-disable-next-line
const Pulse = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 }
})`
  border: 3px solid var(--pink);
  position: absolute;
  border-radius: 100%;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  transform: translate(-100%, -100%);
  opacity: 0;
  animation: pulse 1.5s ease-in-out infinite;
  z-index: 0;
  @keyframes pulse {
    0% {
      transform: scale(1.1, 1.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.95, 1.95);
      opacity: 0;
    }
  }
`;
