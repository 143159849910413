import React from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import useResponseList from "hooks/useResponseList";
import useEmotionsFilter from "hooks/useEmotionsFilter";
import emotionalColors from "styles/emotionalColors";
import { UserResponse } from "types";

function EmotionTally() {
  const sidebarResponses = useResponseList((state) => state.sidebarResponses);
  const selectedEmotions = useEmotionsFilter((state) => state.selectedEmotions);

  const emotionCount = sidebarResponses
    .filter((user: UserResponse) => {
      if (
        selectedEmotions.length === 0 ||
        selectedEmotions.includes(user.primary_emotion)
      ) {
        return true;
      } else {
        return false;
      }
    })
    .reduce(
      (acc: { [key: string]: number }, curr: UserResponse) => {
        acc[curr.primary_emotion]++;

        return acc;
      },
      {
        anger: 0,
        surprise: 0,
        joy: 0,
        disgust: 0,
        sadness: 0,
        fear: 0
      }
    );
  return (
    <EmotionsContainer>
      <EmotionsBlock>
        <EmotionGroup
          count={emotionCount.anger}
          initial={{ scale: 0.925 }}
          style={{ cursor: emotionCount.anger > 0 ? "pointer" : "default" }}
          whileHover={{ scale: emotionCount.anger > 0 ? 1.025 : 0.925 }}
        >
          <EmotionCount>{emotionCount.anger}</EmotionCount>
          <Emotion background={emotionalColors.anger[2]}>Anger</Emotion>
        </EmotionGroup>
        <EmotionGroup
          count={emotionCount.surprise}
          initial={{ scale: 0.925 }}
          style={{
            cursor: emotionCount.surprise > 0 ? "pointer" : "default"
          }}
          whileHover={{ scale: emotionCount.surprise > 0 ? 1.025 : 0.925 }}
        >
          <EmotionCount>{emotionCount.surprise}</EmotionCount>
          <Emotion background={emotionalColors.surprise[2]}>Surprise</Emotion>
        </EmotionGroup>
        <EmotionGroup
          count={emotionCount.joy}
          initial={{ scale: 0.925 }}
          style={{ cursor: emotionCount.joy > 0 ? "pointer" : "default" }}
          whileHover={{ scale: emotionCount.joy > 0 ? 1.025 : 0.925 }}
        >
          <EmotionCount>{emotionCount.joy}</EmotionCount>
          <Emotion background={emotionalColors.joy[2]}>Joy</Emotion>
        </EmotionGroup>
      </EmotionsBlock>
      <EmotionsBlock>
        <EmotionGroup
          count={emotionCount.disgust}
          initial={{ scale: 0.925 }}
          style={{
            cursor: emotionCount.disgust > 0 ? "pointer" : "default"
          }}
          whileHover={{ scale: emotionCount.disgust > 0 ? 1.025 : 0.925 }}
        >
          <EmotionCount>{emotionCount.disgust}</EmotionCount>
          <Emotion background={emotionalColors.disgust[2]}>Disgust</Emotion>
        </EmotionGroup>
        <EmotionGroup
          count={emotionCount.sadness}
          initial={{ scale: 0.925 }}
          style={{
            cursor: emotionCount.sadness > 0 ? "pointer" : "default"
          }}
          whileHover={{ scale: emotionCount.sadness > 0 ? 1.025 : 0.925 }}
        >
          <EmotionCount>{emotionCount.sadness}</EmotionCount>
          <Emotion background={emotionalColors.sadness[2]}>Sadness</Emotion>
        </EmotionGroup>
        <EmotionGroup
          count={emotionCount.fear}
          initial={{ scale: 0.925 }}
          style={{ cursor: emotionCount.fear > 0 ? "pointer" : "default" }}
          whileHover={{ scale: emotionCount.fear > 0 ? 1.025 : 0.925 }}
        >
          <EmotionCount>{emotionCount.fear}</EmotionCount>
          <Emotion background={emotionalColors.fear[2]}>Fear</Emotion>
        </EmotionGroup>
      </EmotionsBlock>
    </EmotionsContainer>
  );
}

export default EmotionTally;

const EmotionsContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 0.25rem;
`;

const EmotionsBlock = styled(motion.div)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0.25rem 0;
`;

const EmotionGroup = styled(motion.div)<{ count: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  ${(props) =>
    props.count === 0 &&
    css`
      filter: grayscale(100%);
    `}
`;

const EmotionCount = styled(motion.div)`
  background: whitesmoke;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 0.75rem;
  padding: 0.25rem;
  font-family: inherit;
  font-variant-numeric: tabular-nums;
`;

const Emotion = styled(motion.div)<{ background: string }>`
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 0.25rem 0.75rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: inherit;
  background: ${(props) => props.background};
  color: black;
`;
