import { UserResponse } from "types";

function buildTopicsFromUsers(users: UserResponse[]): string[] {
  return users.reduce((topics: string[], currentUser: UserResponse) => {
    if (!topics.includes(currentUser.topic)) {
      if (currentUser?.topic?.length > 0) {
        topics.push(currentUser.topic);
      }
    }

    return topics;
  }, []);
}

export default buildTopicsFromUsers;
