import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import ReactGA from "react-ga";
import useUI from "hooks/useUI";
import useGeocoder from "hooks/useGeocoder";
import useMapDrag from "hooks/useMapDrag";
import useOnClickOutside from "hooks/useOnClickOutside";
import useMobileDetect from "hooks/useMobileDetect";
import { MessageRoundedAdd } from "@styled-icons/boxicons-solid/MessageRoundedAdd";

interface AddWidgetProps {
  geocoderRef: React.MutableRefObject<HTMLDivElement | null>;
}

function AddWidget({ geocoderRef }: AddWidgetProps) {
  const showingAddWidget = useUI((state) => state.showingAddWidget);
  const hideAddWidget = useUI((state) => state.hideAddWidget);
  const toggleAddWidget = useUI((state) => state.toggleAddWidget);
  const isDraggingMap = useMapDrag((state) => state.isDraggingMap);
  const showGeocoder = useGeocoder((state) => state.showGeocoder);
  const hideGeocoder = useGeocoder((state) => state.hideGeocoder);
  const geolocationURL = useGeocoder((state) => state.geolocationURL);
  const resetGeolocationURL = useGeocoder((state) => state.resetGeolocationURL);

  const { isMobile } = useMobileDetect();

  const [showingPulse, setShowingPulse] = useState(true);
  const widgetRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(widgetRef, handleClose);

  function handleClick() {
    ReactGA.event({
      category: "Add Your Voice",
      action: `User has clicked to ${
        showingAddWidget ? "close" : "open"
      } the Add Your Voice button from the map.`
    });

    toggleAddWidget();
  }

  function handleClose() {
    if (showingAddWidget === true) {
      hideAddWidget();
    }
  }

  useEffect(() => {
    if (isDraggingMap === true && showingAddWidget === true) {
      hideAddWidget();
    }
  }, [isDraggingMap, showingAddWidget, hideAddWidget]);

  useEffect(() => {
    if (showingAddWidget === true) {
      setShowingPulse(false);
    }
  }, [showingAddWidget]);

  useEffect(() => {
    if (showingAddWidget === true) {
      showGeocoder();
    }

    if (showingAddWidget === false) {
      resetGeolocationURL();
    }

    return () => hideGeocoder();
  }, [showingAddWidget, showGeocoder, hideGeocoder, resetGeolocationURL]);

  return (
    <ButtonGroupWrapper
      animate={isDraggingMap ? { x: "160%" } : { x: "0%" }}
      transition={{ ease: "linear" }}
      isMobile={isMobile()}
    >
      <AddWidgetWrapper ref={widgetRef}>
        <AddIcon onClick={handleClick} />
        {showingPulse && <Pulse />}
        <AnimatePresence exitBeforeEnter>
          {showingAddWidget && (
            <AddWindow
              initial={{ opacity: 0, scale: 0, x: "0%", y: "-110%" }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
            >
              <Header>Tell us how you feel:</Header>

              <Step>
                <StepText>
                  <StepDescription>
                    <Searchbar ref={geocoderRef} />
                    <CheckmarkContainer>
                      {geolocationURL.length > 0 && (
                        <CheckmarkIcon
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ width: 25, height: 25 }}
                          viewBox="0 0 52 52"
                        >
                          <CheckmarkCircle cx="26" cy="26" r="25" fill="none" />
                          <CheckmarkPath
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                          />
                        </CheckmarkIcon>
                      )}
                    </CheckmarkContainer>
                  </StepDescription>
                </StepText>
              </Step>

              {geolocationURL.length > 0 && (
                <NextStepButton
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 2 }}
                >
                  <motion.a
                    href={geolocationURL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Start chatting!
                  </motion.a>{" "}
                </NextStepButton>
              )}
            </AddWindow>
          )}
        </AnimatePresence>
      </AddWidgetWrapper>
    </ButtonGroupWrapper>
  );
}

export default AddWidget;

const ButtonGroupWrapper = styled(motion.div)<{ isMobile: boolean }>`
  position: absolute;
  bottom: ${(props) => (props.isMobile ? "7.5%" : "6%")};
  right: ${(props) => (props.isMobile ? "3%" : "1.5%")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
`;

const AddWidgetWrapper = styled(motion.div)`
  padding: 0.5rem;
  width: auto;
  height: auto;
  background: var(--pink);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  cursor: pointer;
  position: relative;
  z-index: 1000;
`;

const AddIcon = styled(MessageRoundedAdd)`
  width: 30px;
  height: 30px;
  display: block;
  color: rgba(234, 237, 239, 0.95);
`;

const AddWindow = styled(motion.div)`
  position: absolute;
  top: 15%;
  right: 0;
  width: 350px;
  height: auto;
  transform-origin: 90% 100%;
  background: rgba(234, 237, 239, 0.95);
  border-radius: 10px;
  padding: 1rem;
  letter-spacing: 0.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
  z-index: 10000;
`;

const Header = styled(motion.div)`
  font-size: 1.05rem;
  letter-spacing: 1px;
  line-height: 1.3;
  text-align: center;
  padding: 0.5rem 0.25rem;

  span {
    font-style: italic;
  }
`;

const Searchbar = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-right: 1rem;

  * {
    font-family: "Sen", sans-serif;
  }
`;

const Step = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
`;

const StepText = styled(motion.div)`
  width: 100%;
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 1.3;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StepDescription = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0.9rem;
  letter-spacing: 1px;
  line-height: 1.3;
  text-align: center;
  padding: 0.25rem;

  a {
    color: cornflowerblue;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
  }
`;

const NextStepButton = styled(motion.div)`
  border-radius: 10px;
  font-size: 0.8rem;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  background: cornflowerblue;
  color: white;
  cursor: pointer;
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Pulse = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 }
})`
  border: 3px solid var(--pink);
  position: absolute;
  border-radius: 100%;
  height: 100%;
  width: 100%;
  top: 0%;
  right: 0%;
  transform: translate(-100%, -100%);
  opacity: 0;
  animation: pulse 1.5s ease-in-out infinite;
  z-index: -1;
  @keyframes pulse {
    0% {
      transform: scale(1.1, 1.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.95, 1.95);
      opacity: 0;
    }
  }
`;

const stroke = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const fill = keyframes`
  100% {
    box-shadow: inset 0 0 0 100vh cornflowerblue;
  }
`;

const scale = keyframes`
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const CheckmarkContainer = styled(motion.div)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckmarkIcon = styled(motion.svg)`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  stroke: #fff;
  stroke-width: 5;
  stroke-miterlimit: 10;
  animation: ${fill} 0.4s ease-in-out 0.4s forwards,
    ${scale} 0.3s ease-in-out 0.9s both;
`;

const CheckmarkCircle = styled(motion.circle)`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5;
  stroke-miterlimit: 10;
  stroke: cornflowerblue;
  fill: none;
  animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`;

const CheckmarkPath = styled(motion.path)`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
`;
