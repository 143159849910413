import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { css } from "styled-components";
import {
  motion,
  AnimatePresence,
  AnimateSharedLayout,
  Variants
} from "framer-motion";
import ReactGA from "react-ga";
import { ExitIcon } from "components/shared";
import { useCookies } from "react-cookie";
import useUI from "hooks/useUI";
import useViewport from "hooks/useViewport";
import useMobileDetect from "hooks/useMobileDetect";
import useOnClickOutside from "hooks/useOnClickOutside";

const slides = [
  {
    type: "question",
    title: "How do you feel right now?",
    text: "Choose one or more:",
    content: [
      { id: 1, emotion: "Angry", color: "#DA2325" },
      { id: 2, emotion: "Suprised", color: "#DE8631" },
      { id: 3, emotion: "Joyful", color: "#F9F817" },
      { id: 4, emotion: "Disgusted", color: "#89DE31" },
      { id: 5, emotion: "Sad", color: "#1A9DEE" },
      { id: 6, emotion: "Afraid", color: "#9A53E3" }
    ],
    src: ""
  },
  {
    type: "question",
    title: "Thanks, now explore the map to see how others are feeling!",
    src: "",
    linkToTour: true
  }
];

const parent: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { when: "beforeChildren", staggerChildren: 0.2 }
  }
};

const child: Variants = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0
  }
};

function EmotionPromptModal() {
  const [cookies, setCookie] = useCookies(["first_time"]);
  const isMapLoaded = useViewport((state) => state.isMapLoaded);
  const showTutorial = useUI((state) => state.showTutorial);
  const showPianoAnimation = useUI((state) => state.showPianoAnimation);
  const showingEmotionModal = useUI((state) => state.showingEmotionModal);
  const hideEmotionModal = useUI((state) => state.hideEmotionModal);
  const { isMobile } = useMobileDetect();

  const [screenIndex, setScreenIndex] = useState(0);
  const [selectedEmotions, setSelectedEmotions] = useState<number[]>([]);

  const ref = useRef<HTMLDivElement>(null);
  const tourLinkRef = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    ReactGA.event({
      category: "Intro Modal",
      action: "Go To Map/close button"
    });

    hideEmotionModal();
    setCookie("first_time", true, {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    });
    showPianoAnimation();
  }, [hideEmotionModal, showPianoAnimation, setCookie]);

  const handlePrev = useCallback(() => {
    if (screenIndex - 1 >= 0) {
      setScreenIndex((curr) => curr - 1);
    }
  }, [screenIndex]);

  const handleNext = useCallback(() => {
    if (screenIndex + 1 <= slides.length - 1) {
      ReactGA.event({
        category: "Intro Modal",
        action: "Click hdyf - Done button"
      });

      setScreenIndex((curr) => curr + 1);
    }

    if (screenIndex === slides.length - 1) {
      handleClose();
    }
  }, [screenIndex, handleClose]);

  function handleItemClick(id: number) {
    if (!selectedEmotions.includes(id)) {
      ReactGA.event({
        category: "Intro Modal",
        action: "Click hdyf - emotion buttons",
        value: id
      });

      setSelectedEmotions((prevEmotions) => [...prevEmotions, id]);
    } else if (selectedEmotions.includes(id)) {
      setSelectedEmotions((prevEmotions) =>
        prevEmotions.filter((emotionId) => emotionId !== id)
      );
    }
  }

  function handleTourClick() {
    ReactGA.event({
      category: "Intro Modal",
      action: "Click thanks - Take a Quick Tour button"
    });

    hideEmotionModal();
    setCookie("first_time", true, {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    });
    showTutorial();
  }

  useEffect(() => {
    function handleKeyPress(event: KeyboardEvent) {
      if (showingEmotionModal === true) {
        if (event.keyCode === 37) {
          handlePrev();
        }

        if (event.keyCode === 39) {
          handleNext();
        }

        if (event.keyCode === 27) {
          handleClose();
        }
      }
    }

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handlePrev, handleNext, handleClose, showingEmotionModal]);

  const firstTime = cookies.first_time === undefined;

  useOnClickOutside(ref, handleClose);
  useOnClickOutside(tourLinkRef, handleClose);

  // useEffect(() => {
  //   if (isMapLoaded === true) {
  //     if (firstTime === true) {
  //       ReactGA.event({
  //         category: "Intro Modal",
  //         action: "View hdyf - modal loaded"
  //       });

  //       showEmotionModal();
  //     }
  //   }
  // }, [isMapLoaded, firstTime, showEmotionModal]);

  const currentSlide = slides[screenIndex];

  if (isMapLoaded) {
    return (
      <AnimatePresence exitBeforeEnter>
        {showingEmotionModal && firstTime && (
          <TutorialWrapper
            key="wrapper"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            data-testid="emotion-prompt-modal"
          >
            <AnimateSharedLayout>
              {!currentSlide.linkToTour && (
                <ExitIconContainer onClick={handleClose}>
                  <ExitIcon />
                </ExitIconContainer>
              )}

              <ContentContainer
                key={currentSlide.title}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                ref={ref}
              >
                <Title
                  layoutId="title"
                  initial={{ opacity: 0 }}
                  animate
                  isMobile={isMobile()}
                >
                  {currentSlide.title}
                </Title>
                {currentSlide.linkToTour && (
                  <ExitButton
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    onClick={handleClose}
                  >
                    Go to Map
                  </ExitButton>
                )}
                {currentSlide.text && <Text>{currentSlide.text}</Text>}
                {currentSlide.content && (
                  <>
                    <EmotionsContainer
                      initial="hidden"
                      animate="visible"
                      variants={parent}
                    >
                      {currentSlide.content.map(
                        (emotion: {
                          id: number;
                          emotion: string;
                          color: string;
                        }) => (
                          <EmotionItem
                            key={emotion.id}
                            variants={child}
                            whileTap={{ y: -2 }}
                            onClick={() => handleItemClick(emotion.id)}
                            selected={selectedEmotions.includes(emotion.id)}
                          >
                            <EmotionName animate>{emotion.emotion}</EmotionName>
                            <EmotionColor color={emotion.color} />
                          </EmotionItem>
                        )
                      )}
                      <ButtonContainer variants={child}>
                        <NextButton
                          onClick={handleNext}
                          selected={selectedEmotions.length > 0}
                        >
                          Done
                        </NextButton>
                      </ButtonContainer>
                    </EmotionsContainer>
                  </>
                )}
                {currentSlide.linkToTour && (
                  <TourLinkContainer
                    initial={{ x: "-50%", opacity: 0 }}
                    animate={{ opacity: 1 }}
                    ref={tourLinkRef}
                  >
                    <TourText>First time?</TourText>
                    <TourButton onClick={handleTourClick}>
                      Take a Quick Tour
                    </TourButton>
                  </TourLinkContainer>
                )}
              </ContentContainer>
            </AnimateSharedLayout>
          </TutorialWrapper>
        )}
      </AnimatePresence>
    );
  }

  return null;
}

export default EmotionPromptModal;

const TutorialWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: rgba(0 0 0 / 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ExitIconContainer = styled(motion.div)`
  position: absolute;
  top: 4%;
  left: 3%;
  width: 35px;
  height: 35px;
  cursor: pointer;
`;

const ContentContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled(motion.div)<{ isMobile?: boolean }>`
  color: white;
  font-size: 1.5rem;
  letter-spacing: 1px;
  text-align: center;
  margin: 1rem 0;
  ${(props) => props.isMobile && `max-width: 90%;`}
`;

const Text = styled(motion.p)`
  color: white;
  line-height: 1.3;
  font-size: 0.9rem;
  letter-spacing: 0.75px;
  text-align: center;
`;

const EmotionsContainer = styled(motion.ul)`
  width: 100%;
  height: 100%;
  max-height: 325px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
`;

const EmotionItem = styled(motion.li)<{ selected: boolean }>`
  list-style: none;
  text-decoration: none;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  letter-spacing: 0.5px;
  background: none;
  cursor: pointer;
  border-radius: 15px;
  width: 60%;
  height: 100%;
  ${(props) =>
    props.selected &&
    css`
      background: white;
      color: black;
    `}
`;

const EmotionName = styled(motion.div)`
  color: inherit;
`;

const EmotionColor = styled(motion.div)<{ color: string }>`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background: ${(props) => props.color};
`;

const ButtonContainer = styled(motion.div)`
  margin-top: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  text-align: center;
`;

const NextButton = styled(motion.button)<{
  disable?: boolean;
  selected?: boolean;
}>`
  background: cornflowerblue;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  letter-spacing: 1px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 50%);
  margin: 1rem 0;

  ${(props) =>
    props.disable &&
    css`
      filter: grayscale(100%);
    `}

  ${(props) =>
    props.selected === false &&
    css`
      background: #ccc;
      color: white;
    `}
`;

const ExitButton = styled(motion.div)`
  background: cornflowerblue;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  letter-spacing: 1px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 50%);
  margin: 1rem 0;
`;

const TourLinkContainer = styled(motion.div)`
  position: absolute;
  bottom: 20%;
  left: 50%;
  width: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5rem;
  place-items: center;
  text-align: center;
`;

const TourText = styled(motion.p)`
  font-size: 1.1rem;
  letter-spacing: 1px;
  color: white;
`;

const TourButton = styled(motion.button)`
  background: cornflowerblue;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  letter-spacing: 1px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 50%);
`;
