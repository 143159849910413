import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import ReactGA from "react-ga";
import SymplerLogo from "components/SymplerLogo";
import useUI from "hooks/useUI";
import useMapDrag from "hooks/useMapDrag";
import useMobileDetect from "hooks/useMobileDetect";

function Branding() {
  const isDraggingMap = useMapDrag((state) => state.isDraggingMap);
  const showingSidebar = useUI((state) => state.showingSidebar);
  const { isMobile } = useMobileDetect();

  function handleClick() {
    ReactGA.event({
      category: "Map",
      action: "User clicked on the Sympler logo in map view."
    });
  }

  return (
    <>
      {!isMobile() && (
        <AnimatePresence exitBeforeEnter initial={false}>
          {((isDraggingMap === true && showingSidebar === true) ||
            showingSidebar === false) && (
            <SymplerLogoContainer
              href="https://sympler.co"
              target="_blank"
              rel="noreferrer"
              initial={{ opacity: 0 }}
              animate={
                isDraggingMap
                  ? { x: "-25%", scale: 0.9, opacity: 0.9 }
                  : { x: "0%", scale: 1, opacity: 1 }
              }
              exit={{ opacity: 0 }}
              onClick={handleClick}
            >
              <SymplerLogo />
            </SymplerLogoContainer>
          )}
        </AnimatePresence>
      )}
    </>
  );
}

export default Branding;

const SymplerLogoContainer = styled(motion.a)`
  position: absolute;
  width: 10%;
  height: 5%;
  min-width: 105px;
  min-height: 35px;
  bottom: 1.5%;
  left: 3.25%;
`;
