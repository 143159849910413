import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { FlyToInterpolator } from "react-map-gl";
import ReactGA from "react-ga";
import useViewport from "hooks/useViewport";
import useUI from "hooks/useUI";
import useMobileDetect from "hooks/useMobileDetect";
import useOnClickOutside from "hooks/useOnClickOutside";
import useInsights from "hooks/useInsights";

function ToastNotification() {
  // Global State
  const isMapLoaded = useViewport((state) => state.isMapLoaded);
  const updateViewport = useViewport((state) => state.updateViewport);
  const highlightedIndex = useInsights((state) => state.highlightedIndex);
  const highlightedUsers = useInsights((state) => state.highlightedUsers);
  const enterInsightMode = useInsights((state) => state.enterInsightMode);
  const showingEmotionModal = useUI((state) => state.showingEmotionModal);
  const showingTutorial = useUI((state) => state.showingTutorial);
  const showingToast = useUI((state) => state.showingToast);
  const showToast = useUI((state) => state.showToast);
  const hideToast = useUI((state) => state.hideToast);
  const { isMobile } = useMobileDetect();

  const [userHasDismissed, setUserHasDismissed] = useState(false);
  const toastRef = useRef<HTMLDivElement | null>(null);

  const highlightedUser = highlightedUsers[highlightedIndex];

  useOnClickOutside(toastRef, handleClick);

  function handleClick() {
    ReactGA.event({
      category: "Toast Notification",
      action: "User has dismissed the toast notification"
    });

    setUserHasDismissed(true);
    hideToast();
  }

  function handleTourStart() {
    ReactGA.event({
      category: "Toast Notification",
      action:
        "User has clicked on the toast notification to view Daily Reflections"
    });

    hideToast();

    if (process.env.NODE_ENV !== "test") {
      updateViewport({
        longitude: highlightedUser.long,
        latitude: highlightedUser.lat,
        zoom: 4,
        transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
        transitionDuration: "auto"
      });
    }

    enterInsightMode();
  }

  useEffect(() => {
    if (isMapLoaded) {
      if (highlightedUsers.length > 0) {
        if (showingEmotionModal === false) {
          if (showingTutorial === false) {
            showToast();
          }
        }
      }
    }
  }, [
    isMapLoaded,
    highlightedUsers,
    showingEmotionModal,
    showingTutorial,
    showToast
  ]);

  return (
    <AnimatePresence exitBeforeEnter>
      {showingToast && userHasDismissed === false && (
        <ToastNotificationWrapper
          initial={{ x: "-50%", opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          isMobile={isMobile()}
          ref={toastRef}
        >
          <Text isMobile={isMobile()}>
            Looking for some insights? Have a quick look at our
          </Text>
          <Button role="button" onClick={handleTourStart}>
            Daily Reflections
          </Button>
        </ToastNotificationWrapper>
      )}
    </AnimatePresence>
  );
}

export default ToastNotification;

const ToastNotificationWrapper = styled(motion.div)<{ isMobile: boolean }>`
  position: absolute;
  bottom: 12.5%;
  left: 50%;
  transform-origin: 50% 50%;
  width: auto;
  white-space: nowrap;
  /* background: var(--pink); */
  border-radius: 5px;
  padding: 0.5rem 2rem;
  /* box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  ${(props) =>
    props.isMobile &&
    css`
      flex-direction: column;
      bottom: 10%;
      white-space: normal;
      width: 85%;
      padding: 0.5rem 1rem;
    `}
`;

const Text = styled(motion.div)<{ isMobile: boolean }>`
  font-size: 1rem;
  letter-spacing: 1px;
  color: white;
  margin-right: 1rem;

  ${(props) =>
    props.isMobile &&
    css`
      margin-right: 0;
      margin-bottom: 0.5rem;
    `}
`;

const Button = styled(motion.div)`
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background: var(--pink);
  color: white;
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 0.8rem;
`;
