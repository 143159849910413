import create from "zustand";
import { UserResponse } from "types";

interface UserResponseState {
  userResponse: UserResponse;
  updateUserResponse: (newUserResponse: UserResponse) => void;
  resetUserResponse: () => void;
}

const [useUserResponse, api] = create<UserResponseState>((set) => ({
  userResponse: {} as UserResponse,
  updateUserResponse: (newUserResponse: UserResponse) =>
    set((state) => ({ userResponse: newUserResponse })),
  resetUserResponse: () =>
    set((state) => ({ userResponse: {} as UserResponse }))
}));

export default useUserResponse;

export { api };
