import create from "zustand";
import { HighlightedUserResponse } from "types";
import { api as useMapDragAPI } from "./useMapDrag";

const { updateMapDrag } = useMapDragAPI.getState();

interface InsightsState {
  highlightedUsers: HighlightedUserResponse[];
  inInsightMode: boolean;
  highlightedIndex: number;
  isTransitioningBetweenInsights: boolean;
  updateHighlightedIndex: (newIndex: number) => void;
  updateHighlightedUsers: (newUsers: HighlightedUserResponse[]) => void;
  enterInsightMode: () => void;
  exitInsightMode: () => void;
  setTransitionBetweenInsights: (newValue: boolean) => void;
}

const [useInsights, api] = create<InsightsState>((set) => ({
  highlightedUsers: [],
  inInsightMode: false,
  highlightedIndex: 0,
  isTransitioningBetweenInsights: false,
  updateHighlightedIndex: (newIndex: number) =>
    set((state) => ({
      highlightedIndex: newIndex
    })),
  updateHighlightedUsers: (newUsers: HighlightedUserResponse[]) =>
    set((state) => ({
      highlightedUsers: newUsers
    })),

  enterInsightMode: () =>
    set((state) => {
      updateMapDrag(true);
      return {
        inInsightMode: true
      };
    }),
  exitInsightMode: () =>
    set((state) => {
      updateMapDrag(false);
      return {
        inInsightMode: false
      };
    }),
  setTransitionBetweenInsights: (newValue: boolean) =>
    set((state) => ({
      isTransitioningBetweenInsights: newValue
    }))
}));

export default useInsights;

export { api };
