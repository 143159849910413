import React from "react";
import { Helmet } from "react-helmet";

interface HelmetProps {
  title: string;
  description?: string;
  pathname: string;
  url?: string;
  children?: React.ReactChildren;
}

const url = `https://howdoyoufeel.live`;

const seoDescription =
  "How Do You Feel aims to connect real world stories & faces to build a sense of empathy around COVID-19.";

const getMetaTags = ({ title, description, url }: HelmetProps) => {
  const metaTags = [
    { charset: "utf-8" },
    {
      "http-equiv": "X-UA-Compatible",
      content: "IE=edge"
    },
    {
      name: "viewport",
      content: "width=device-width, initial-scale=1"
    },
    {
      name: "theme-color",
      content: "#fff"
    },
    { itemprop: "name", content: title },
    { itemprop: "description", content: description },
    { name: "description", content: description },
    { name: "twitter:site", content: "Sympler" },
    { name: "twitter:title", content: title },
    { name: "twitter:description", content: description },
    { property: "og:title", content: title },
    { property: "og:url", content: url },
    { property: "og:description", content: description },
    { property: "og:site_name", content: "Sympler" }
  ];

  return metaTags;
};

function SEO({
  title,
  pathname,
  description = seoDescription,
  children
}: HelmetProps) {
  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title}
      meta={getMetaTags({
        title,
        description,
        pathname,
        url
      })}
    >
      {children}
    </Helmet>
  );
}

export default SEO;
