import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { FlyToInterpolator } from "react-map-gl";
import ReactGA from "react-ga";
import TimeAgo from "timeago-react";
import { ExitIcon, Checkbox } from "components/shared";
import useInsights from "hooks/useInsights";
import useViewport from "hooks/useViewport";
import useCookies from "hooks/useCookies";
import useStickyState from "hooks/useStickyState";
import useOnClickOutside from "hooks/useOnClickOutside";

function InsightView() {
  const inInsightMode = useInsights((state) => state.inInsightMode);
  const exitInsightMode = useInsights((state) => state.exitInsightMode);
  const highlightedIndex = useInsights((state) => state.highlightedIndex);
  const updateHighlightedIndex = useInsights(
    (state) => state.updateHighlightedIndex
  );
  const highlightedUsers = useInsights((state) => state.highlightedUsers);
  const isTransitioningBetweenInsights = useInsights(
    (state) => state.isTransitioningBetweenInsights
  );
  const setTransitionBetweenInsights = useInsights(
    (state) => state.setTransitionBetweenInsights
  );
  const updateViewport = useViewport((state) => state.updateViewport);
  const { cookie, shouldSetCookie, addCookie } = useCookies(
    highlightedUsers.length
  );

  function buildDefaultUserInfo() {
    return {
      reflections: Object.fromEntries(
        highlightedUsers.map((user) => [user.id, false])
      ),
      preferences: {
        autoplay: true
      }
    };
  }

  const [userInfo, setUserInfo] = useStickyState(
    buildDefaultUserInfo(),
    "user"
  );

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, handleExit);

  function handleHighlightClick(id: number) {
    ReactGA.event({
      category: "Daily Reflections",
      action: "User has clicked on a Daily Reflection",
      label: `Daily Reflection id ${id}`
    });

    const newIndex = highlightedUsers.findIndex((user) => user.id === id);

    if (newIndex === highlightedIndex) return;

    const newUser = highlightedUsers[newIndex];

    updateHighlightedIndex(newIndex);

    if (process.env.NODE_ENV !== "test") {
      setTransitionBetweenInsights(true);

      updateViewport({
        longitude: newUser.long,
        latitude: newUser.lat,
        zoom: 4,
        transitionInterpolator: new FlyToInterpolator({ speed: 0.5 }),
        transitionDuration: "auto"
      });
    }
  }

  function toggleAutoplay() {
    ReactGA.event({
      category: "Daily Reflections",
      action: `User has toggled autoplay ${
        userInfo.preferences.autoplay ? "off" : "on"
      }`
    });

    setUserInfo((prevInfo: any) => ({
      ...prevInfo,
      preferences: {
        ...prevInfo.preferences,
        autoplay: !prevInfo.preferences.autoplay
      }
    }));
  }

  function handleExit() {
    ReactGA.event({
      category: "Daily Reflections",
      action: "User has exited the Daily Reflections modal"
    });

    exitInsightMode();
  }

  const highlightedUser = highlightedUsers[highlightedIndex];

  useEffect(() => {
    if (highlightedUsers.length > 0) {
      updateHighlightedIndex(highlightedUsers.length - 1);
    }
  }, [highlightedUsers, updateHighlightedIndex]);

  useEffect(() => {
    if (highlightedUsers.length > 0) {
      if (userInfo.reflections.length === 0) {
        setUserInfo((prevInfo: any) => ({
          ...prevInfo,
          reflections: Object.fromEntries(
            highlightedUsers.map((user) => [user.id, false])
          )
        }));
      }
    }
  }, [highlightedUsers, userInfo, setUserInfo]);

  useEffect(() => {
    if (inInsightMode) {
      if (cookie === undefined || shouldSetCookie) {
        addCookie();
      }
    }
  }, [inInsightMode, cookie, shouldSetCookie, addCookie]);

  useEffect(() => {
    if (inInsightMode) {
      if (highlightedUser?.id) {
        setUserInfo((prevInfo: any) => ({
          ...prevInfo,
          reflections: {
            ...prevInfo.reflections,
            [highlightedUser.id]: true
          }
        }));
      }
    }
  }, [inInsightMode, highlightedUser, setUserInfo]);

  return (
    <AnimatePresence exitBeforeEnter>
      {inInsightMode && !isTransitioningBetweenInsights && (
        <InsightViewWrapper
          key="insight"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          data-testid="insight-view"
        >
          <ExitIconContainer>
            <ExitIcon />
          </ExitIconContainer>
          <ContentWrapper ref={ref}>
            {/* Title Header */}
            <TitleHeader>
              <TitleHeaderText>Daily Reflection</TitleHeaderText>
            </TitleHeader>

            {/* Header */}
            <HeaderContainer>
              <HeaderText>All Reflections</HeaderText>
            </HeaderContainer>

            {/* List */}
            <ListContainer>
              <List>
                {highlightedUsers
                  .map((user) => {
                    const showBadge =
                      userInfo.reflections[user.id] === false ||
                      !userInfo.reflections.hasOwnProperty(user.id);

                    const isSelected = user.id === highlightedUser.id;

                    return (
                      <ListItem
                        key={user.id}
                        onClick={() => handleHighlightClick(user.id)}
                        selected={isSelected}
                        role="button"
                      >
                        <ListItemLocation>
                          {user.city}, {user.state}
                        </ListItemLocation>

                        <ListItemInsight>{user.highlight_text}</ListItemInsight>

                        <AnimatePresence exitBeforeEnter>
                          {showBadge && (
                            <BadgeContainer
                              key={user.id}
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0, scale: 0 }}
                            >
                              <Badge>
                                <BadgeText>New</BadgeText>
                              </Badge>
                            </BadgeContainer>
                          )}
                        </AnimatePresence>
                      </ListItem>
                    );
                  })
                  .reverse()}
              </List>
            </ListContainer>

            {/* Scroll Bar */}
            <BarContainer></BarContainer>

            {/* Video */}
            <VideoContainer>
              <Video
                loop
                controls
                disablePictureInPicture
                controlsList="nodownload noremoteplayback"
                autoPlay={userInfo.preferences.autoplay}
                key={highlightedUser.sympler_mirror}
              >
                <source src={highlightedUser.sympler_mirror} type="video/mp4" />
                <p>Loading...</p>
              </Video>
              <ControlItemContainer>
                <ControlItem onClick={toggleAutoplay}>
                  <Checkbox filled={userInfo.preferences.autoplay} />
                  Autoplay
                </ControlItem>
              </ControlItemContainer>
            </VideoContainer>

            {/* Insight */}
            <InsightContainer>
              <InsightText data-testid="insight-text">
                {highlightedUser.highlight_text}
              </InsightText>
              <AdditionalInfo>
                <Location>
                  {highlightedUser.city}, {highlightedUser.state}
                </Location>
                <Time
                  datetime={highlightedUser.highlight_date}
                  locale="en_US"
                />
              </AdditionalInfo>
            </InsightContainer>
          </ContentWrapper>
        </InsightViewWrapper>
      )}
    </AnimatePresence>
  );
}

export default InsightView;

const InsightViewWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: rgba(0 0 0 / 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;

  @media screen and (max-width: 750px) {
    background: rgba(0 0 0 / 0.9);
    flex-direction: column;
  }
`;

const ExitIconContainer = styled(motion.div)`
  position: absolute;
  top: 4%;
  left: 3%;
  width: 35px;
  height: 35px;
  cursor: pointer;
`;

const ContentWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    "header . bar header2"
    "video insight bar list"
    "video insight bar list";
  grid-template-columns: 300px auto 1px minmax(auto, 300px);
  grid-template-rows: 40px 390px 40px;
  grid-row-gap: 1rem;
  grid-column-gap: 2rem;
  justify-content: center;
  align-items: center;
  color: white;

  @media screen and (max-width: 750px) {
    grid-template-areas:
      "header"
      "video"
      "insight"
      "header2"
      "list"
      "controls";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto 400px auto;
    overflow: auto;
  }
`;

const TitleHeader = styled(motion.div)`
  grid-area: header;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 750px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const TitleHeaderText = styled(motion.p)`
  font-size: 1.4rem;
  letter-spacing: 2px;
  text-align: left;

  @media screen and (max-width: 750px) {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
`;

const HeaderContainer = styled(motion.div)`
  grid-area: header2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const HeaderText = styled(motion.p)`
  margin-right: 1rem;
  font-size: 1.4rem;
  letter-spacing: 2px;
  text-align: right;

  @media screen and (max-width: 750px) {
    margin-right: 0;
    text-align: center;
    width: 100%;
    font-size: 1.4rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

const ListContainer = styled(motion.div)`
  grid-area: list;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: auto;
`;

const List = styled(motion.ul)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media screen and (max-width: 750px) {
    width: 90%;
  }
`;

const ListItem = styled(motion.li)<{ selected: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  background: ${(props) => (props.selected ? "var(--blue)" : "none")};

  @media screen and (max-width: 750px) {
    text-align: center;
    margin: 0 0.5rem;
  }
`;

const ListItemLocation = styled(motion.div)`
  width: 80%;
  font-size: 1rem;
  letter-spacing: 0.7px;
  position: relative;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
`;

const ListItemInsight = styled(motion.div)`
  margin-top: 0.4rem;
  display: -webkit-box;
  width: 100%;
  font-size: 0.8rem;
  text-align: right;
  letter-spacing: 0.7px;
  font-style: italic;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media screen and (max-width: 750px) {
    text-align: center;
  }
`;

const BadgeContainer = styled(motion.div)`
  position: absolute;
  top: 15%;
  left: 2%;
`;

const Badge = styled(motion.div)`
  background: var(--dark-blue);
  padding: 0.25rem 0.5rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
`;

const BadgeText = styled(motion.span)`
  font-size: 0.7rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: white;
`;

const ControlItemContainer = styled(motion.div)`
  margin-top: 1rem;
  display: flex;
`;

const ControlItem = styled(motion.li)`
  width: 100%;
  list-style: none;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
  margin: 0.25rem 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`;

const BarContainer = styled(motion.div)`
  grid-area: bar;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: white;
  border-radius: 2px;

  @media screen and (max-width: 750px) {
    display: none;
  }
`;

const VideoContainer = styled(motion.div)`
  grid-area: video;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px 100px; */

  @media screen and (max-width: 750px) {
    padding: 0;
  }
`;

const Video = styled(motion.video)`
  width: 275px;
  height: 375px;
  border-radius: 10px;
  object-fit: cover;
  object-position: 50% 50%;

  @media screen and (max-width: 750px) {
    width: 250px;
    height: 350px;
  }
`;

const InsightContainer = styled(motion.div)`
  grid-area: insight;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
`;

const InsightText = styled(motion.p)`
  font-size: 1.3rem;
  letter-spacing: 1px;
  line-height: 1.25;
  margin-bottom: 2rem;
  max-width: 400px;

  @media screen and (max-width: 750px) {
    margin-bottom: 0;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
  }
`;

const AdditionalInfo = styled(motion.div)`
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Location = styled(motion.div)`
  font-size: 1rem;
  letter-spacing: 0.75px;
  line-height: 1.3;
`;

const Time = styled(TimeAgo)`
  font-size: 0.8rem;
  letter-spacing: 0.7px;
  text-transform: capitalize;
  font-style: italic;
`;
