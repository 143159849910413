import determinePrimaryEmotion from "./determinePrimaryEmotion";
import { InitialUserResponse, UserResponse } from "types";

function getUserResponses(
  initialResponses: InitialUserResponse[]
): UserResponse[] {
  console.log(initialResponses.length);
  const responses = initialResponses
    .filter((response: InitialUserResponse) => {
      // Remove the response if it has been marked as removed in Google Sheets
      if (response.removed === "1") {
        return false;
      }

      // Remove the response if it is related to question 1 or 2
      if (
        response.question_number === "1" ||
        response.question_number === "2"
      ) {
        return false;
      }

      // Remove the response if the city/state are not valid
      if (response.city.length === 0 || response.state.length === 0) {
        return false;
      }

      return true;
    })
    // Turn the response from the BE into a response the FE can use
    .map((response: InitialUserResponse) => {
      const emotionalMultiplier = 5;

      const emotionScores = {
        anger: Number(response.emotion_anger) * emotionalMultiplier,
        surprise: Number(response.emotion_surprise) * emotionalMultiplier,
        joy: Number(response.emotion_joy) * emotionalMultiplier,
        disgust: Number(response.emotion_disgust) * emotionalMultiplier,
        sadness: Number(response.emotion_sadness) * emotionalMultiplier,
        fear: Number(response.emotion_fear) * emotionalMultiplier
      };

      const primaryEmotion = determinePrimaryEmotion(emotionScores);

      return {
        id: Number(response.id),
        timestamp: new Date(response.timestamp.replace(/-/g, "/")),
        firstname: response.firstname,
        last_user_input: response.last_user_input,
        social_profile_picture: response.social_profile_picture,
        question_count: Number(response.question_number),
        user_input: response.user_input,
        campaign_name: response.campaign_name,
        sympler_mirror: response.media_url.replace(
          "http://dashboard.sympler.live/",
          "https://hdyf-prod-api.sympler.co/"
        ),
        removed: Number(response.removed),
        zip: response.zip,
        lat: Number(response.lat),
        long: Number(response.long),
        city: response.city,
        state: response.state,
        topic: response.subtopic,
        question: response.question,
        emotion_scores: emotionScores,
        primary_emotion: primaryEmotion
      };
    });

  return responses;
}

export default getUserResponses;
