import create from "zustand";

interface TimeToggleProps {
  filterStartTime: number | null;
  filterEndTime: number | null;
  startDateIndex: number | null;
  endDateIndex: number | null;
  uniqueDates: string[];
  dateRange: [string, string];
  setFilterStartTime: (newStartTime: number) => void;
  setFilterEndTime: (newEndTime: number) => void;
  setStartDateIndex: (newStartDateIndex: number) => void;
  setEndDateIndex: (newEndDateIndex: number) => void;
  updateUniqueDates: (newUniqueDates: string[]) => void;
  initializeIndexes: () => void;
  initializeTimestamps: () => void;
  updateDateRange: (newDateRange: [string, string]) => void;
}

const [useTimeToggle, api] = create<TimeToggleProps>((set, get) => ({
  filterStartTime: null,
  filterEndTime: null,
  startDateIndex: null,
  endDateIndex: null,
  uniqueDates: [],
  dateRange: ["", ""],
  setFilterStartTime: (newStartTime: number) =>
    set((state) => ({ filterStartTime: newStartTime })),
  setFilterEndTime: (newEndTime: number) =>
    set((state) => ({ filterEndTime: newEndTime })),
  setStartDateIndex: (newStartDateIndex: number) =>
    set((state) => ({ startDateIndex: newStartDateIndex })),
  setEndDateIndex: (newEndDateIndex: number) =>
    set((state) => ({ endDateIndex: newEndDateIndex })),
  updateUniqueDates: (newUniqueDates: string[]) =>
    set((state) => ({ uniqueDates: newUniqueDates })),
  initializeIndexes: () => {
    const uniqueDates = get().uniqueDates;

    // One Week Ago
    // const oneWeekAgo = uniqueDates.length - 8 > 0 ? uniqueDates.length - 8 : 0;
    // const today = uniqueDates.length - 1;

    // set((state) => ({
    //   startDateIndex: oneWeekAgo,
    //   endDateIndex: today,
    //   dateRange: [uniqueDates[oneWeekAgo], uniqueDates[uniqueDates.length - 1]]
    // }));

    // Starting at April 5th => Today
    set((state) => ({
      startDateIndex: 3,
      endDateIndex: uniqueDates.length - 1,
      dateRange: [uniqueDates[3], uniqueDates[uniqueDates.length - 1]]
    }));
  },
  initializeTimestamps: () => {
    const uniqueDates = get().uniqueDates;
    const startUniqueDateIndex = get().startDateIndex;
    const endUniqueDateIndex = get().endDateIndex;

    if (startUniqueDateIndex !== null && endUniqueDateIndex !== null) {
      const startDateTimestamp = `${uniqueDates[startUniqueDateIndex]} 2020 00:00:00`;
      const endDateTimestamp = `${uniqueDates[endUniqueDateIndex]} 2020 23:59:59`;
      const startingDateTimestamp = new Date(startDateTimestamp).getTime();
      const endingDateTimestamp = new Date(endDateTimestamp).getTime();

      set((state) => ({
        filterStartTime: startingDateTimestamp,
        filterEndTime: endingDateTimestamp
      }));
    }
  },
  updateDateRange: (newDateRange: [string, string]) =>
    set((state) => ({ dateRange: newDateRange }))
}));

export default useTimeToggle;

export { api };
