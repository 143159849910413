import React from "react";
import ReactDOM from "react-dom";
import App from "components/App";

// GA tracking
import ReactGA from "react-ga";
ReactGA.initialize("UA-163770592-1");
ReactGA.pageview("Initial page view");

ReactDOM.render(<App />, document.getElementById("root"));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// import * as serviceWorker from "./serviceWorker";
// serviceWorker.unregister();
