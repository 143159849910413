import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import DateRange from "components/DateRange";
import useMapDrag from "hooks/useMapDrag";
import useMobileDetect from "hooks/useMobileDetect";

function MapFilters() {
  const isDraggingMap = useMapDrag((state) => state.isDraggingMap);
  const { isMobile } = useMobileDetect();

  return (
    <MapFiltersWrapper
      initial={{ x: "-50%" }}
      animate={
        isDraggingMap ? { y: "-135%", opacity: 0.9 } : { y: "0%", opacity: 1 }
      }
      transition={{ ease: "linear" }}
    >
      <FilterContainer isMobile={isMobile()}>
        <DateRange />
      </FilterContainer>
    </MapFiltersWrapper>
  );
}

export default MapFilters;

const MapFiltersWrapper = styled(motion.div)`
  position: absolute;
  top: 2.5%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

const FilterContainer = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})<{ isMobile: boolean }>`
  width: 350px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.isMobile && `width: 300px`}
`;
