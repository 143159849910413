import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import ReactGA from "react-ga";
import UserResults from "components/UserResults";
import TopicsFilter from "components/TopicsFilter";
import EmotionTally from "components/EmotionTally";
import useUI from "hooks/useUI";
import useMapDrag from "hooks/useMapDrag";
import useResponseList from "hooks/useResponseList";
import useTopicsFilter from "hooks/useTopicsFilter";
import useMobileDetect from "hooks/useMobileDetect";
import { ChevronsRight } from "@styled-icons/boxicons-solid/ChevronsRight";

function Sidebar() {
  const isDraggingMap = useMapDrag((state) => state.isDraggingMap);
  const showingSidebar = useUI((state) => state.showingSidebar);
  const toggleSidebar = useUI((state) => state.toggleSidebar);
  const sidebarResponses = useResponseList((state) => state.sidebarResponses);
  const topics = useTopicsFilter((state) => state.topics);
  const { isMobile } = useMobileDetect();

  function handleSidebarClick() {
    ReactGA.event({
      category: "Sidebar",
      action: `User has clicked to ${
        showingSidebar ? "close" : "open"
      } the Sidebar.`
    });

    toggleSidebar();
  }

  return (
    <SidebarContainer
      initial={{ x: "-100%" }}
      animate={
        isDraggingMap
          ? { x: "-115%" }
          : showingSidebar
          ? { x: "0" }
          : { x: "-92.5%" }
      }
      transition={{ ease: "linear" }}
    >
      <SidebarWrapper>
        {/* <SidebarHeader>
          <SidebarText>Responses</SidebarText>
        </SidebarHeader> */}
        <TopicsContainer>
          <TopicsFilter topics={topics} />
        </TopicsContainer>

        {isMobile() && (
          <EmotionTallyContainer>
            <EmotionTally />
          </EmotionTallyContainer>
        )}

        <AnimatePresence exitBeforeEnter>
          {sidebarResponses.length > 0 ? (
            <UserResults />
          ) : (
            <SidebarSuggestion>
              Click anywhere on the map to see responses from that area!
            </SidebarSuggestion>
          )}
        </AnimatePresence>
      </SidebarWrapper>
      <SidebarToggle
        initial={{ y: "-50%" }}
        whileHover={{ scale: 1.05 }}
        animate={
          showingSidebar ? { rotate: 180, y: "-50%" } : { rotate: 0, y: "-50%" }
        }
        onClick={handleSidebarClick}
        data-testid="sidebar-toggle"
      >
        <SidebarIcon />
      </SidebarToggle>
    </SidebarContainer>
  );
}

export default Sidebar;

const SidebarContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 330px;
  max-width: 20%;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
`;

const TopicsContainer = styled(motion.div)`
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SidebarWrapper = styled(motion.aside)`
  width: 100%;
  height: 100%;
  background: whitesmoke;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 1rem;
`;

// const SidebarHeader = styled(motion.div)`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   text-align: center;
//   width: 100%;
//   position: relative;
//   font-size: 1.1rem;
//   margin: 0.5rem 0;
// `;

// const SidebarText = styled(motion.div)`
//   width: 100%;
//   font-family: "Montserrat", sans-serif;
//   font-size: 1.1rem;
//   text-transform: uppercase;
//   letter-spacing: 1px;
//   text-align: center;
// `;

const SidebarToggle = styled(motion.div)`
  position: absolute;
  top: 50%;
  right: -7.5%;
  width: 40px;
  height: 40px;
  padding: 0.25rem;
  background: var(--gray);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2001;
  cursor: pointer;
`;

const SidebarIcon = styled(ChevronsRight)`
  color: var(--text);
  width: 25px;
  height: 25px;
  display: block;
`;

const SidebarSuggestion = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
})`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10%;
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 1.5;
  color: var(--text);
  flex: 1;
`;

// const SymplerLogoContainer = styled(motion.a).attrs({
//   initial: { opacity: 0 },
//   animate: { opacity: 1 },
//   exit: { opacity: 0 }
// })`
//   width: 100%;
//   height: 35px;
// `;

const EmotionTallyContainer = styled(motion.div)`
  margin: 0.5rem;
`;
