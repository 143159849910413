import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { CookiesProvider } from "react-cookie";
import GlobalStyles from "styles/global";
import SEO from "components/SEO";

function Layout({ children }: { children: React.ReactNode }) {
  const height = window.innerHeight;

  return (
    <CookiesProvider>
      <LayoutWrapper style={{ height }}>
        <SEO title="How Do You Feel" pathname={window.location.pathname} />
        <GlobalStyles />
        {children}
      </LayoutWrapper>
    </CookiesProvider>
  );
}

export default Layout;

const LayoutWrapper = styled(motion.div)`
  width: 100vw;
  overflow: hidden;
  position: relative;
`;
