import create from "zustand";
import { ViewportProps } from "react-map-gl";

const isAndroid = () => Boolean(navigator.userAgent.match(/Android/i));
const isIos = () => Boolean(navigator.userAgent.match(/iPhone|iPad|iPod/i));
const isOpera = () => Boolean(navigator.userAgent.match(/Opera Mini/i));
const isWindows = () => Boolean(navigator.userAgent.match(/IEMobile/i));
const isMobile = () =>
  Boolean(isAndroid() || isIos() || isOpera() || isWindows());

let initialState = {};

if (isMobile()) {
  initialState = {
    longitude: -99.96825857745267,
    latitude: 41.35352612836889,
    zoom: 2
  };
} else {
  initialState = {
    longitude: -51.71409392412308,
    latitude: 42.363110362470536,
    zoom: 2.4210355212548755
  };
}

interface ViewportState {
  viewport: ViewportProps;
  isMapLoaded: boolean;
  updateViewport: (newViewport: Partial<ViewportProps>) => void;
  updateMapLoaded: (newBool: boolean) => void;
  resetViewport: () => void;
}

const [useViewport, api] = create<ViewportState>((set) => ({
  viewport: initialState as ViewportProps,
  isMapLoaded: false,
  updateViewport: (newViewport: Partial<ViewportProps>) =>
    set((state) => ({ viewport: { ...state.viewport, ...newViewport } })),
  updateMapLoaded: (newBool: boolean) =>
    set((state) => ({ isMapLoaded: newBool })),
  resetViewport: () =>
    set((state) => ({ viewport: { ...state.viewport, ...initialState } }))
}));

export default useViewport;

export { api };
