import { LocationObject } from "types";

async function buildLocationObject(
  longitude: number,
  latitude: number
): Promise<LocationObject> {
  const token =
    "pk.eyJ1IjoibWlrZW91ciIsImEiOiJja2F6d3Q2ZmswMnh0MnNtcDFmbHhyOXA4In0.IDoEem78LN1Hu9qARCAnDw";

  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${token}`;

  const fetchGeocodeInfomation = await fetch(url).then((raw) => raw.json());

  const context = fetchGeocodeInfomation.features[0].context;

  const zip = context.find((feature: any) => feature.id.includes("postcode"))
    ?.text;

  const city = context.find((feature: any) => feature.id.includes("place"))
    ?.text;

  const state = context.find((feature: any) => feature.id.includes("region"))
    ?.text;

  const country = context.find((feature: any) => feature.id.includes("country"))
    ?.text;

  return {
    zip,
    city,
    state,
    country,
    longitude: String(longitude),
    latitude: String(latitude)
  };
}

export default buildLocationObject;

/*
  United Kingdom
  buildLocationObject(-3.203314, 55.942595);

  response =>
    {
      "zip": "EH3 9JP",
      "city": "Edinburgh",
      "state": "Scotland",
      "country": "United Kingdom",
      "longitude": "-3.203314",
      "latitude": "55.942595"
    }
*/

/*
  Ireland
  buildLocationObject(-5.876774, 54.696923;

  response =>
    {
      "zip": "BT388SP",
      "city": "Carrickfergus",
      "state": "Northern Ireland",
      "country": "United Kingdom",
      "longitude": "-5.876774",
      "latitude": "54.696923"
    }
*/

/*
  United States
  buildLocationObject(-122.17332, 37.738379);

  response =>
    {
      "zip": "94603",
      "city": "Oakland",
      "state": "California",
      "country": "United States",
      "longitude": "-122.17332",
      "latitude": "37.738379"
    }
*/
