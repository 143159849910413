import React, { useEffect, useRef } from "react";
import Layout from "components/Layout";
import Sidebar from "components/Sidebar";
import Map from "components/Map";
import MapFilters from "components/MapFilters";
import EmotionMapKey from "components/EmotionMapKey";
import Options from "components/Options";
import AddWidget from "components/AddWidget";
import NavigationControls from "components/NavigationControls";
import Branding from "components/Branding";
import Tutorial from "components/Tutorial";
import ToastNotification from "components/ToastNotification";
import InsightView from "components/InsightView";
import EmotionPromptModal from "components/EmotionPromptModal";
import useUsers from "hooks/useUsers";
import useTopicsFilter from "hooks/useTopicsFilter";
import useTimeToggle from "hooks/useTimeToggle";
import useInsights from "hooks/useInsights";
import {
  buildTopicsFromUsers,
  buildUniqueDatesFromUsers,
  fetchUsers
} from "utils";

function App() {
  /********************** Global State ************************/

  const users = useUsers((state) => state.users);
  const updateUsers = useUsers((state) => state.updateUsers);
  const updateTopics = useTopicsFilter((state) => state.updateTopics);
  const uniqueDates = useTimeToggle((state) => state.uniqueDates);
  const updateUniqueDates = useTimeToggle((state) => state.updateUniqueDates);
  const initializeIndexes = useTimeToggle((state) => state.initializeIndexes);
  const initializeTimestamps = useTimeToggle(
    (state) => state.initializeTimestamps
  );
  const updateHighlightedUsers = useInsights(
    (state) => state.updateHighlightedUsers
  );

  /************************* Refs ****************************/

  const geocoderRef = useRef<HTMLDivElement | null>(null);

  /************************ Effects **************************/

  useEffect(() => {
    async function getUsers() {
      try {
        const { fetchedUsers, fetchedHighlightedUsers } = await fetchUsers();

        updateUsers(fetchedUsers);
        updateHighlightedUsers(fetchedHighlightedUsers);
      } catch (err) {
        // console.log("Error fetching users from server", err.message);
        updateUsers([]);
        updateHighlightedUsers([]);
      }
    }

    getUsers();
  }, [updateUsers, updateHighlightedUsers]);

  useEffect(() => {
    if (users.length > 0) {
      const topics = buildTopicsFromUsers(users);
      const uniqueDates = buildUniqueDatesFromUsers(users);

      updateTopics(topics);
      updateUniqueDates(uniqueDates);
    }
  }, [users, updateTopics, updateUniqueDates]);

  useEffect(() => {
    if (uniqueDates.length > 0) {
      initializeIndexes();
      initializeTimestamps();
    }
  }, [uniqueDates, initializeIndexes, initializeTimestamps]);

  return (
    <Layout>
      <EmotionPromptModal />
      <Map geocoderRef={geocoderRef} />
      <MapFilters />
      <Sidebar />
      <EmotionMapKey />
      <Options />
      <AddWidget geocoderRef={geocoderRef} />
      <InsightView />
      <Tutorial />
      <Branding />
      <ToastNotification />
      <NavigationControls />
    </Layout>
  );
}

export default App;
