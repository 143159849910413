import create from "zustand";
import { EmotionType } from "types";

interface EmotionsFilterState {
  selectedEmotions: EmotionType[];
  updateSelectedEmotion: (emotion: EmotionType) => void;
  updateAllEmotions: (emotions: EmotionType[]) => void;
}

const [useEmotionsFilter, api] = create<EmotionsFilterState>((set) => ({
  selectedEmotions: [],
  updateSelectedEmotion: (emotion: EmotionType) =>
    set((state) => {
      if (state.selectedEmotions.includes(emotion)) {
        return {
          selectedEmotions: state.selectedEmotions.filter(
            (currentEmotion) => currentEmotion !== emotion
          )
        };
      } else {
        return {
          selectedEmotions: [...state.selectedEmotions, emotion]
        };
      }
    }),
  updateAllEmotions: (emotions: EmotionType[]) =>
    set((state) => ({
      selectedEmotions: emotions
    }))
}));

export default useEmotionsFilter;

export { api };
