import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import TimeAgo from "timeago-react";
import UserProfilePicture from "components/UserProfilePicture";
import useUI from "hooks/useUI";
import useUserResponse from "hooks/useUserResponse";
import emotionalColors from "styles/emotionalColors";
import { Close } from "@styled-icons/material/Close";
import { LocationOn } from "@styled-icons/material/LocationOn";
import { QuestionMarkCircleOutline } from "@styled-icons/evaicons-outline/QuestionMarkCircleOutline";
import { UserResponse } from "types";

interface ResponseProps {
  user: UserResponse;
  markerRef?: React.MutableRefObject<HTMLDivElement | null>;
  setOffsets?: React.Dispatch<
    React.SetStateAction<{
      left: number;
      top: number;
    }>
  >;
  highlightStyling?: boolean;
}

function Response({
  user,
  markerRef,
  setOffsets,
  highlightStyling
}: ResponseProps) {
  const updateUserResponse = useUserResponse(
    (state) => state.updateUserResponse
  );
  const hideUserResponse = useUI((state) => state.hideUserResponse);

  const [showQuestionText, setShowQuestionText] = useState(false);

  function handleClick() {
    setShowQuestionText((prevState) => !prevState);
  }

  function handleClose() {
    updateUserResponse({} as UserResponse);
    hideUserResponse();
  }

  useEffect(() => {
    // Every render, grab the size of the div, update the offset
    // level so it is perfectly centered in the Map view

    if (markerRef?.current) {
      if (setOffsets) {
        const top = (markerRef.current.offsetHeight * -1) / 2;
        const left = (markerRef.current.offsetWidth * -1) / 2;

        setOffsets({
          top,
          left
        });
      }
    }
  }, [markerRef, setOffsets, user]);

  const text = showQuestionText ? user.question : user.user_input;

  return (
    <UserResponseWrapper ref={markerRef}>
      <ContentName>
        <ContentRow>
          {!highlightStyling && (
            <IconContainer>
              <LocationIcon />
            </IconContainer>
          )}

          <ContentBlock highlightStyling={highlightStyling}>
            <City highlightStyling={highlightStyling}>
              {user.city || ""}
              {","}
            </City>
            <State highlightStyling={highlightStyling}>
              {user.state || ""}
            </State>
          </ContentBlock>
        </ContentRow>
        {!highlightStyling && (
          <QuestionPill
            space
            clickable
            showQuestionText={showQuestionText}
            whileHover={{ scale: 1.025 }}
            onClick={handleClick}
          >
            <IconContainer>
              <QuestionIcon />
            </IconContainer>
            <Text>See Question</Text>
          </QuestionPill>
        )}
      </ContentName>
      {user.sympler_mirror.endsWith(".mp4") ? (
        <ContentVideo key={user.id} highlightStyling={highlightStyling}>
          {showQuestionText && (
            <QuestionDropdown>{user.question}</QuestionDropdown>
          )}
          <Video controls>
            <source src={user.sympler_mirror} type="video/mp4" />
          </Video>
        </ContentVideo>
      ) : (
        <ContentResponse usersPrimaryEmotion={user.primary_emotion}>
          {showQuestionText && (
            <QuestionDropdown>{user.question}</QuestionDropdown>
          )}
          <ContentText>{text}</ContentText>
        </ContentResponse>
      )}

      <ProfilePictureContainer>
        <UserProfilePicture src={user.social_profile_picture} />
      </ProfilePictureContainer>

      {!highlightStyling && (
        <TimestampContainer>
          <Timestamp datetime={user.timestamp} locale="en_US" />
        </TimestampContainer>
      )}

      {!highlightStyling && (
        <CloseButtonContainer onClick={handleClose}>
          <CloseButton />
        </CloseButtonContainer>
      )}
    </UserResponseWrapper>
  );
}

export default React.memo(Response);

const UserResponseWrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 }
})`
  background: whitesmoke;
  color: black;
  border-radius: 10px;
  width: 300px;
  position: relative;
  padding: 0.5rem;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ContentVideo = styled(motion.div)<{ highlightStyling?: boolean }>`
  grid-area: content;
  flex: 4;
  width: 100%;
  height: 100%;
  min-height: 325px;
  max-height: 425px;
  background: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 10px;
`;

const Video = styled(motion.video)`
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border: none;
  outline: none;
`;

const ContentName = styled.div`
  grid-area: name;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  padding: 0.5rem;
`;

const ContentRow = styled(motion.div)<{ space?: boolean; clickable?: boolean }>`
  width: 100%;
  max-width: 185px;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  ${(props) =>
    props.space &&
    css`
      margin-top: 0.5rem;
    `}
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
`;

const QuestionPill = styled(motion.div)<{
  space?: boolean;
  clickable?: boolean;
  showQuestionText: boolean;
}>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  border: 1px solid cornflowerblue;
  border-radius: 15px;
  padding: 0.25rem 0.75rem 0.25rem 0.5rem;
  width: 100%;
  height: 100%;
  max-width: 185px;
   ${(props) =>
     props.space &&
     css`
       margin-top: 0.5rem;
     `}
  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}
  background: ${(props) =>
    props.showQuestionText ? "cornflowerblue" : "white"};
  color: ${(props) => (props.showQuestionText ? "white" : "cornflowerblue")};
`;

const City = styled.div<{ highlightStyling?: boolean }>`
  font-size: ${(props) => (props.highlightStyling ? "1.3rem" : "1.25rem")};
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: left;
`;

const State = styled.div<{ highlightStyling?: boolean }>`
  font-size: ${(props) => (props.highlightStyling ? "1rem" : "0.85rem")};
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: left;
`;

const ContentBlock = styled.div<{ highlightStyling?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  text-align: flex-start;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.highlightStyling &&
    css`
      padding-bottom: 1rem;
    `}
`;

const Text = styled(motion.div)`
  font-size: 0.85rem;
  color: inherit;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: left;
  flex: 1;
`;

const ContentResponse = styled(motion.div)<{
  usersPrimaryEmotion?:
    | boolean
    | "anger"
    | "sadness"
    | "fear"
    | "joy"
    | "surprise"
    | "disgust"
    | undefined;
}>`
  background: ${(props) => {
    switch (props.usersPrimaryEmotion) {
      case "sadness":
        return emotionalColors.sadness[2];
      case "anger":
        return emotionalColors.anger[2];
      case "joy":
        return emotionalColors.joy[2];
      case "disgust":
        return emotionalColors.disgust[2];
      case "surprise":
        return emotionalColors.surprise[2];
      case "fear":
        return emotionalColors.fear[2];
      default:
        return "whitesmoke";
    }
  }};
  grid-area: content;
  width: 100%;
  max-height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  border-radius: 10px;
  padding: 1.25rem 1rem 1rem 1rem;
  box-shadow: 10px 10px 33px -15px rgba(0, 0, 0, 0.47);
  position: relative;
  overflow: auto;
`;

const QuestionDropdown = styled(motion.div)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  border-radius: 10px;
  padding: 1rem;
  font-size: 0.9rem;
  line-height: 1.3;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
  z-index: 4;
`;

const ContentText = styled(motion.div)`
  width: 100%;
  height: 100%;
  font-size: 0.9rem;
  line-height: 1.3;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.5px;
`;

const ProfilePictureContainer = styled(motion.div)`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3px;
  background: whitesmoke;
  border-radius: 50%;
  box-shadow: 10px 10px 33px -15px rgba(0, 0, 0, 0.47);
  z-index: 2;
`;

const IconContainer = styled(motion.div)`
  flex: 0;
  margin-right: 0.75rem;
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: inherit;
`;

const QuestionIcon = styled(QuestionMarkCircleOutline)`
  width: 1rem;
  height: 1rem;
  display: block;
  color: currentColor;
`;

const LocationIcon = styled(LocationOn)`
  width: 1rem;
  height: 1rem;
  display: block;
  color: black;
`;

const CloseButtonContainer = styled(motion.div)`
  width: 25px;
  height: 25px;
  padding: 1%;
  position: absolute;
  top: -5px;
  left: -5px;
  border-radius: 50%;
  background: whitesmoke;
  box-shadow: 10px 10px 33px -15px rgba(0, 0, 0, 0.47);
  cursor: pointer;
  z-index: 2;
`;

const CloseButton = styled(Close)`
  width: 100%;
  height: 100%;
  display: block;
  color: black;
`;

const TimestampContainer = styled(motion.div)`
  position: absolute;
  bottom: -2.5%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  background: whitesmoke;
  padding: 0.25rem 0.5rem;
  box-shadow: 10px 10px 33px -15px rgba(0, 0, 0, 0.47);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Timestamp = styled(TimeAgo)`
  font-size: 0.8rem;
  text-transform: uppercase;
`;
