import create from "zustand";

interface UserInterfaceProps {
  // Add Widget
  showingAddWidget: boolean;
  showAddWidget: () => void;
  hideAddWidget: () => void;
  toggleAddWidget: () => void;

  // Emotion Modal
  showingEmotionModal: boolean;
  showEmotionModal: () => void;
  hideEmotionModal: () => void;

  // Piano Animation
  showingPianoAnimation: boolean;
  showPianoAnimation: () => void;
  hidePianoAnimation: () => void;

  // Options Bar
  showingOptionsBar: boolean;
  showOptionsBar: () => void;
  hideOptionsBar: () => void;
  toggleOptionsBar: () => void;

  // Sidebar
  showingSidebar: boolean;
  showSidebar: () => void;
  hideSidebar: () => void;
  toggleSidebar: () => void;

  // Toast
  showingToast: boolean;
  showToast: () => void;
  hideToast: () => void;

  // Tutorial
  showingTutorial: boolean;
  showTutorial: () => void;
  hideTutorial: () => void;

  // User Response
  showingUserResponse: boolean;
  showUserResponse: () => void;
  hideUserResponse: () => void;
}

const [useUI, api] = create<UserInterfaceProps>((set) => ({
  showingAddWidget: false,
  showAddWidget: () => set((state) => ({ showingAddWidget: true })),
  hideAddWidget: () => set((state) => ({ showingAddWidget: false })),
  toggleAddWidget: () =>
    set((state) => ({ showingAddWidget: !state.showingAddWidget })),
  showingEmotionModal: false,
  showEmotionModal: () => set((state) => ({ showingEmotionModal: true })),
  hideEmotionModal: () => set((state) => ({ showingEmotionModal: false })),
  showingPianoAnimation: false,
  showPianoAnimation: () => set((state) => ({ showingPianoAnimation: true })),
  hidePianoAnimation: () => set((state) => ({ showingPianoAnimation: false })),
  showingOptionsBar: false,
  showOptionsBar: () => set((state) => ({ showingOptionsBar: true })),
  hideOptionsBar: () => set((state) => ({ showingOptionsBar: false })),
  toggleOptionsBar: () =>
    set((state) => ({ showingOptionsBar: !state.showingOptionsBar })),
  showingSidebar: false,
  showSidebar: () => set((state) => ({ showingSidebar: true })),
  hideSidebar: () => set((state) => ({ showingSidebar: false })),
  toggleSidebar: () =>
    set((state) => ({
      showingSidebar: !state.showingSidebar
    })),
  showingToast: false,
  showToast: () => set((state) => ({ showingToast: true })),
  hideToast: () => set((state) => ({ showingToast: false })),
  showingTutorial: false,
  showTutorial: () => set((state) => ({ showingTutorial: true })),
  hideTutorial: () => set((state) => ({ showingTutorial: false })),
  showingUserResponse: false,
  showUserResponse: () => set((state) => ({ showingUserResponse: true })),
  hideUserResponse: () => set((state) => ({ showingUserResponse: false }))
}));

export default useUI;

export { api };
