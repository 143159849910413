import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import DefaultProfilePic from "assets/images/default-profile-pic.png";

interface UserProfilePictureProps {
  src: string;
}

function UserProfilePicture({ src }: UserProfilePictureProps) {
  function handleImageError(
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) {
    (event.target as HTMLImageElement).src = DefaultProfilePic;
    (event.target as HTMLImageElement).style.width = "auto";
    (event.target as HTMLImageElement).style.boxShadow = "none";
  }

  return <ProfilePicture src={src} onError={handleImageError} />;
}

export default UserProfilePicture;

const ProfilePicture = styled(motion.img)`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
`;
