import React from "react";
import { motion } from "framer-motion";

interface CheckboxProps {
  filled: boolean;
}

function Checkbox({ filled }: CheckboxProps) {
  return (
    <motion.svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      whileTap={{ y: -2 }}
    >
      <rect
        x="0.5"
        y="0.5"
        width="13"
        height="13"
        rx="2"
        fill={filled === true ? "#6495ED" : "none"}
        stroke="white"
      />
      {filled === true && (
        <>
          <line
            x1="6.17989"
            y1="9.22025"
            x2="3.84656"
            y2="6.88692"
            stroke="white"
          />
          <line
            x1="6.17989"
            y1="8.51315"
            x2="10.8466"
            y2="3.84648"
            stroke="white"
          />
        </>
      )}
    </motion.svg>
  );
}

export default Checkbox;
