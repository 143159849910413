import determinePrimaryEmotion from "./determinePrimaryEmotion";
import { InitialHighlightUserResponse, HighlightedUserResponse } from "types";

function getHighlightedUsers(
  responses: InitialHighlightUserResponse[]
): HighlightedUserResponse[] {
  const highlightedUsers = responses.map(
    (response: InitialHighlightUserResponse) => {
      const emotionalMultiplier = 20;

      const emotionScores = {
        anger: Number(response.emotion_anger) * emotionalMultiplier,
        surprise: Number(response.emotion_surprise) * emotionalMultiplier,
        joy: Number(response.emotion_joy) * emotionalMultiplier,
        disgust: Number(response.emotion_disgust) * emotionalMultiplier,
        sadness: Number(response.emotion_sadness) * emotionalMultiplier,
        fear: Number(response.emotion_fear) * emotionalMultiplier
      };

      const primaryEmotion = determinePrimaryEmotion(emotionScores);

      return {
        id: Number(response.id),
        timestamp: new Date(response.timestamp),
        firstname: response.firstname,
        last_user_input: response.last_user_input,
        social_profile_picture: response.social_profile_picture,
        question_count: Number(response.question_number),
        user_input: response.user_input,
        campaign_name: response.campaign_name,
        sympler_mirror: response.media_url.replace(
          "http://dashboard.sympler.live/",
          "https://hdyf-prod-api.sympler.co/"
        ),
        removed: Number(response.removed),
        zip: response.zip,
        lat: Number(response.lat),
        long: Number(response.long),
        city: response.city,
        state: response.state,
        topic: response.subtopic,
        question: response.question,
        emotion_scores: emotionScores,
        primary_emotion: primaryEmotion,
        highlight_text: response.highlight_text,
        highlight_date: response.highlight_date
      } as HighlightedUserResponse;
    }
  );

  return highlightedUsers.slice(0, highlightedUsers.length);
}

export default getHighlightedUsers;
